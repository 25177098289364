<template>
  <div class="card">
    <!--begin::Card header-->
    <div class="card-header border-0 pt-6">
      <!--begin::Card title-->
      <div class="card-title">
        <!--begin::Search-->
<!--        <div class="d-flex align-items-center position-relative my-1">
          <i class="ki-duotone ki-magnifier fs-3 position-absolute ms-5">
            <span class="path1"></span>
            <span class="path2"></span>
          </i>
          <input type="text" data-kt-user-table-filter="search" class="form-control form-control-solid w-250px ps-13" placeholder="Search" />
        </div>-->
        <!--end::Search-->
      </div>
      <div class="card-toolbar">
        <div class="d-flex justify-content-end" data-kt-user-table-toolbar="base">
          <!--begin::Add user-->
          <button type="button" class="btn btn-primary" data-bs-toggle="modal" data-bs-target="#kt_modal">
            <i class="ki-duotone ki-plus fs-2"></i>Add {{title}}</button>
        </div>
      </div>
      <!--end::Card toolbar-->
    </div>
    <!--end::Card header-->
    <!--begin::Card body-->
    <div class="card-body py-4">
      <!--begin::Table-->
      <datatable ref="dataTable" :url="fetchUrl" :columns="columns"
                 :table-actions="actions"
                 @rowClicked="handleRowInteraction"/>
      <!--end::Table-->
    </div>
    <!--end::Card body-->

    <div class="modal fade" id="kt_modal"  tabindex="-1" aria-hidden="true">
      <!--begin::Modal dialog-->
      <div class="modal-dialog modal-dialog-centered mw-650px">
        <!--begin::Modal content-->
        <div class="modal-content">
          <!--begin::Modal header-->
          <div class="modal-header" id="kt_modal_header">
            <!--begin::Modal title-->
            <h2 class="fw-bold">{{this.selectedRow ? "Update" : "Add"}} {{title}}</h2>
            <!--end::Modal title-->
            <!--begin::Close-->
            <div class="btn btn-icon btn-sm btn-active-icon-primary" data-kt-users-modal-action="close"  data-bs-dismiss="modal">
              <i class="ki-duotone ki-cross fs-1">
                <span class="path1"></span>
                <span class="path2"></span>
              </i>
            </div>
            <!--end::Close-->
          </div>
          <!--end::Modal header-->
          <!--begin::Modal body-->
          <div class="modal-body px-5 my-7">
            <!--begin::Form-->
            <form id="kt_modal_form" class="form" action="#">
              <!--begin::Scroll-->
              <div class="d-flex flex-column scroll-y px-5 px-lg-10" id="kt_modal_scroll" data-kt-scroll="true" data-kt-scroll-activate="true" data-kt-scroll-max-height="auto" data-kt-scroll-dependencies="#kt_modal_header" data-kt-scroll-wrappers="#kt_modal_scroll" data-kt-scroll-offset="300px">
                <!--begin::Input group-->
                <div class="fv-row mb-7" v-for="(field, index) in dynamicFields" ref="dynamicFields" :data-index="index">
                  <label class="required fw-semibold fs-6 mb-2">{{field.label}}</label>
                  <template v-if="field.element !== 'select-custom'">
                    <template v-if="field.element === 'input'">
                      <input :type="field.type"  class="form-control form-control-solid mb-3 mb-lg-0" :placeholder="field.placeholder" />
                    </template>
                  </template>
                  <p class="text-danger">{{field.message}}</p>
                </div>
                <slot name="selectCustom" :onSelect="onCustomSelect"></slot>

                <!--end::Input group-->
              </div>
              <!--end::Scroll-->
              <!--begin::Actions-->
              <div class="text-center pt-10">
                <p class="text-danger">{{ saveErrorMsg }}</p>
                <button type="reset" class="btn btn-light me-3" data-bs-dismiss="modal">Discard</button>
                <loading-button @click.prevent="save"
                                text="Save"
                                active-text="Saving"
                                :active="isSaving"/>
              </div>
              <!--end::Actions-->
            </form>
            <!--end::Form-->
          </div>
          <!--end::Modal body-->
        </div>
        <!--end::Modal content-->
      </div>
      <!--end::Modal dialog-->
    </div>

  </div>

</template>

<script>
  import ApiService from '@/core/services/api-service'
  import { APIS } from '@/core/api-urls'
  import Datatable from '@/components/tables/datatable.vue'
  import LoadingButton from '@/components/buttons/LoadingButton.vue'

  export default {
    name: 'APITable',
    components: { LoadingButton, Datatable,  },
    props: ["title", "api", "param", "fields", "columns", "actions", 'fetchUrl','addUrl', 'editUrl', 'deleteUrl'],
    emits: ["onActionClicked"],
    data () {
      return {

        dynamicFields: [],


        selectCustomModel: null,
        saveErrorMsg:"",
        isSaving: false,
        selectedRow: null,
      }

    },
    async created () {
      this.dynamicFields = this.fields.map((field)=> {
        return {...field, message: ""}
      });


    },
    mounted () {
      $("#kt_modal").on("hidden.bs.modal", (e) => {
        this.selectedRow = null;
      });
    },
    updated () {

    },
    methods: {
      onCustomSelect(value){
        this.selectCustomModel = value;
      },
      async save () {
        console.log(this.selectCustomModel);
        this.saveErrorMsg = "";
        const elements = this.$refs.dynamicFields;
        let isValid = true;
        const payload = {};
        elements.forEach((element)=> {
          const idx = parseInt(element.getAttribute("data-index"));
          const field = this.dynamicFields[idx];

          if(field.element === 'select-custom') {
            payload[field.param] = this.selectCustomModel;
            return;
          }

          const value = element.querySelector("input").value;

          if(field.validate){
            const [valid, errMsg] = field.validate(value);
            if(!valid){
              field.message = errMsg;
              isValid = false;
            }
          }

          payload[field.param] = value;
        });

        if(!isValid)
          return;

        this.isSaving = true;
        try {
          const isUpdate = this.selectedRow !== null;
          const url = isUpdate ? APIS[this.editUrl] : APIS[this.addUrl];
          if(isUpdate){
            payload[this.api] = this.selectedRow[this.param]
            await ApiService.GeneratePutRequest(url, payload);
          }else
            await ApiService.GeneratePostRequest(url, payload);

          elements.forEach((element)=> {
            const input = element.querySelector("input");
            if(input)
              input.value = "";
          });
          Swal.fire({
            text: this.title + (isUpdate ? " Updated" : " Created!"),
            icon: "success",
            buttonsStyling: false,
            confirmButtonText: "Close!",
            customClass: {
              confirmButton: "btn btn-primary"
            }
          });
          this.isSaving = false;
          this.$refs.dataTable.reload();
        }catch (error) {
          this.saveErrorMsg = error.message;
        }

        this.isSaving = false;
      },
      async handleRowInteraction(e, action, data){
        this.$emit("onActionClicked", e, action, data);
        if(action === "edit"){
          this.selectedRow = data;
          const elements = this.$refs.dynamicFields;
          elements.forEach((element)=> {
            const idx = parseInt(element.getAttribute("data-index"));
            const field = this.dynamicFields[idx];

            element.querySelector("input").value = data[field.api];
          });
        }else if(action === "delete"){
          Swal.fire({
            text: `Are you sure you want to delete?`,
            icon: "warning",
            buttonsStyling: false,
            confirmButtonText: "Delete",
            denyButtonText: "CANCEL",
            showDenyButton: true,
            customClass: {
              confirmButton: "btn btn-danger",
              denyButton: "btn btn-primary",
            }
          }).then(async (result) => {
            if(result.isConfirmed){
              try {
                let response = await ApiService.GenerateDeleteRequest(APIS[this.deleteUrl] + data.Id);
                Swal.fire({
                  text: this.title + " Deleted!",
                  icon: "success",
                  buttonsStyling: false,
                  confirmButtonText: "Close!",
                  customClass: {
                    confirmButton: "btn btn-primary"
                  }
                });
                this.$refs.dataTable.reload();
              }catch (error) {
                Swal.fire({
                  text: `Unable to delete ${this.title}! ` + error.message,
                  icon: "error",
                  buttonsStyling: false,
                  confirmButtonText: "Close!",
                  customClass: {
                    confirmButton: "btn btn-primary"
                  }
                });
              }
            }
          });


        }
      }
    }
  }
</script>


