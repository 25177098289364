import {PERMISSION_ACTIONS, PERMISSION_PAGES} from "@/core/constants";

export const menuConfiguration = [
	{
		title: "Dashboard",
		url: "#",
		icon: `<i class="ki-duotone ki-book-open  fs-1">
 <span class="path1"></span>
 <span class="path2"></span>
 <span class="path3"></span>
 <span class="path4"></span>
</i>`,
		permissions: {page: PERMISSION_PAGES.DASHBOARD, action: PERMISSION_ACTIONS.CAN_VIEW}
	},
	{
		title: "Delinquency",
		url: "#",
		icon: `<i class="ki-duotone ki-user-tick  fs-1">
 <span class="path1"></span>
 <span class="path2"></span>
 <span class="path3"></span>
</i>`,
		permissions: {page: PERMISSION_PAGES.DELINQUENCY, action: PERMISSION_ACTIONS.CAN_VIEW}
	},
	{
		title: "Onboarding",
		icon: `<i class="ki-duotone ki-cloud-add  fs-1">
			 <span class="path1"></span>
			 <span class="path2"></span>
			</i>`,
		permissions: {page: PERMISSION_PAGES.ONBOARDING, action: PERMISSION_ACTIONS.CAN_VIEW},
		subMenuItems: [{
			title: "Company",
			url: "/onboard-company",
			permissions: {page: PERMISSION_PAGES.ONBOARDING, action: PERMISSION_ACTIONS.CAN_VIEW}
		}]
	},
	{
		title: "Operations",
		icon: `<i class="ki-duotone ki-finance-calculator">
					 <span class="path1"></span>
					 <span class="path2"></span>
					 <span class="path3"></span>
					 <span class="path4"></span>
					 <span class="path5"></span>
					 <span class="path6"></span>
					 <span class="path7"></span>
					</i>`,
		
		permissions: {page: PERMISSION_PAGES.OPERATIONS, action: PERMISSION_ACTIONS.CAN_VIEW},
		
		subMenuItems: [{
			title: "Operations 1",
			url: "#",
			permissions: {page: PERMISSION_PAGES.OPERATIONS, action: PERMISSION_ACTIONS.CAN_VIEW}
		}]
	},
	{
		title: "Finance",
		icon: `<i class="ki-duotone ki-dollar fs-1">
 <span class="path1"></span>
 <span class="path2"></span>
 <span class="path3"></span>
</i>`,
		permissions: {page: PERMISSION_PAGES.FINANCE, action: PERMISSION_ACTIONS.CAN_VIEW},
		
		subMenuItems: [{
			title: "Invoices",
			url: "#",
			permissions: {page: PERMISSION_PAGES.FINANCE_INVOICE, action: PERMISSION_ACTIONS.CAN_VIEW}
		},{
			title: "Receipts",
			url: "#",
			permissions: {page: PERMISSION_PAGES.FINANCE_RECEIPTS, action: PERMISSION_ACTIONS.CAN_VIEW}
		},{
			title: "Expense Reports",
			url: "#",
			permissions: {page: PERMISSION_PAGES.FINANCE_EXPENSE_REPORTS, action: PERMISSION_ACTIONS.CAN_VIEW}
		},{
			title: "Payment Processing",
			url: "#",
			permissions: {page: PERMISSION_PAGES.FINANCE_PAYMENT_PROCESSING, action: PERMISSION_ACTIONS.CAN_VIEW}
		},]
	},
	{
		title: "Documents",
		icon: `<i class="ki-duotone ki-document fs-1">
 <span class="path1"></span>
 <span class="path2"></span>
 <span class="path3"></span>
</i>`,
		permissions: {page: PERMISSION_PAGES.DOCUMENTS, action: PERMISSION_ACTIONS.CAN_VIEW},
		
		subMenuItems: [{
			title: "Recent Scans",
			url: "#",
			permissions: {page: PERMISSION_PAGES.DOCUMENT_RECENT_SCANS, action: PERMISSION_ACTIONS.CAN_VIEW}
		},{
			title: "Bulk Uploads",
			url: "#",
			permissions: {page: PERMISSION_PAGES.DOCUMENT_BULK_UPLOADS, action: PERMISSION_ACTIONS.CAN_VIEW}
		},{
			title: "Batch Processing",
			url: "#",
			permissions: {page: PERMISSION_PAGES.DOCUMENT_BATCH_PROCESSING, action: PERMISSION_ACTIONS.CAN_VIEW}
		},{
			title: "Operations",
			url: "#",
			permissions: {page: PERMISSION_PAGES.DOCUMENT_OPERATIONS, action: PERMISSION_ACTIONS.CAN_VIEW}
		},]
	},{
		title: "Management",
		icon: `<i class="ki-duotone ki-wrench fs-1">
              <i class="path1"></i>
              <i class="path2"></i>
          </i>`,
		permissions: {page: PERMISSION_PAGES.MANAGEMENT, action: PERMISSION_ACTIONS.CAN_VIEW},
		
		subMenuItems: [{
			title: "User Management",
			url: "/manage/users",
			permissions: {page: PERMISSION_PAGES.MANAGE_USERS, action: PERMISSION_ACTIONS.CAN_VIEW}
		},{
			title: "Role Management",
			url: "/manage/roles",
			permissions: {page: PERMISSION_PAGES.MANAGE_ROLES, action: PERMISSION_ACTIONS.CAN_VIEW}
		},{
			title: "Permission Management",
			url: "/manage/role-permissions",
			permissions: {page: PERMISSION_PAGES.MANAGE_ROLE_PERMISSIONS, action: PERMISSION_ACTIONS.CAN_VIEW}
		},{
			title: "Company Management",
			url: "/manage/companies",
			permissions: {page: PERMISSION_PAGES.MANAGE_COMPANIES, action: PERMISSION_ACTIONS.CAN_VIEW}
		}]
	},{
		title: "Reports",
		icon: `<i class="ki-duotone ki-note  fs-1">
 <span class="path1"></span>
 <span class="path2"></span>
</i>`,
		permissions: {page: PERMISSION_PAGES.REPORTS, action: PERMISSION_ACTIONS.CAN_VIEW},
		
		subMenuItems: [{
			title: "Document",
			url: "#",
			permissions: {page: PERMISSION_PAGES.REPORT_DOCUMENT, action: PERMISSION_ACTIONS.CAN_VIEW}
		},{
			title: "User",
			url: "#",
			permissions: {page: PERMISSION_PAGES.REPORT_USER, action: PERMISSION_ACTIONS.CAN_VIEW}
		},{
			title: "System",
			url: "#",
			permissions: {page: PERMISSION_PAGES.REPORT_SYSTEM, action: PERMISSION_ACTIONS.CAN_VIEW}
		},]
	},
]