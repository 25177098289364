import store from "@/store/index"
import {PERMISSION_ACTIONS, PERMISSION_PAGES} from "@/core/constants";

export default class PermissionService {
	static checkPermission(page, action) {
		const permissions = store.getters["Auth/permissions"];
		if(!page || !action)
			return  false;
		if (page === PERMISSION_PAGES.DEFAULT && action === PERMISSION_ACTIONS.DEFAULT)
			return true
		
		return permissions.some(permission => permission.page === page && permission.action === action);
	}
}