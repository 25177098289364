<template>
  <div class="app-sidebar-menu overflow-hidden flex-column-fluid">
    <!--begin::Menu wrapper-->
    <div id="kt_app_sidebar_menu_wrapper" class="app-sidebar-wrapper hover-scroll-overlay-y my-5" data-kt-scroll="true"
         data-kt-scroll-activate="true" data-kt-scroll-height="auto"
         data-kt-scroll-dependencies="#kt_app_sidebar_logo, #kt_app_sidebar_footer"
         data-kt-scroll-wrappers="#kt_app_sidebar_menu" data-kt-scroll-offset="5px" data-kt-scroll-save-state="true">
      <!--begin::Menu-->
      <div class="menu menu-column menu-rounded menu-sub-indention px-3" id="#kt_app_sidebar_menu" data-kt-menu="true"
           data-kt-menu-expand="false">
        <!--end:Menu item-->

        <template v-for="item in menuConfiguration">
          <div data-kt-menu-trigger="click" class="menu-item menu-accordion hover ">
            <!--begin:Menu link-->

            <template v-if="item.subMenuItems">
              <permission-hide  :page="item.permissions.page" :action="item.permissions.action">
                <span class="menu-link">
                  <span class="menu-icon" v-html="item.icon">
                  </span>
                  <span class="menu-title">{{ item.title }}</span>
                  <span class="menu-arrow"></span>
                </span>
              </permission-hide>
            </template>
            <template v-else>
              <permission-hide  :page="item.permissions.page" :action="item.permissions.action">
                  <div class="menu-item">
                    <span class="menu-link">
                          <span class="menu-icon" v-html="item.icon"></span>
                          <span class="menu-title">{{ item.title }}</span>
                   </span>
                  </div>
              </permission-hide>
            </template>

            <template v-if="item.subMenuItems">
              <div class="menu-sub menu-sub-accordion " kt-hidden-height="242" style="">
                <!--begin:Menu item-->
                <template  v-for="subItem in item.subMenuItems">
                  <permission-hide  :page="item.permissions.page" :action="item.permissions.action">
                    <div class="menu-item">
                    <!--begin:Menu link-->
                    <router-link class="menu-link" :to="subItem.url">
                	    <span class="menu-bullet">
													<span class="bullet bullet-dot"></span>
											</span>
                      <span class="menu-title">{{ subItem.title }}</span>
                    </router-link>
                    <!--end:Menu link-->
                  </div>
                  </permission-hide>
                </template>

                <!--end:Menu item-->

              </div>
            </template>
          </div>
        </template>
      </div>
    </div>
    <!--end::Menu wrapper-->
  </div>
</template>

<script>
  import PermissionService from '@/core/services/permission-service'
  import { menuConfiguration } from '@/config/menu'
  import PermissionMenu from '@/components/menu/permission-menu-root-item.vue'
  import PermissionMenuSubItem from '@/components/menu/permission-menu-sub-item.vue'
  import PermissionMenuRootItem from '@/components/menu/permission-menu-root-item.vue'
  import PermissionHide from '@/components/menu/permission-hide.vue'

  export default {
    name: 'LeftMenu',
    components: { PermissionHide, PermissionMenuRootItem, PermissionMenuSubItem, PermissionMenu },
    data () {
      return {
        menuConfiguration: menuConfiguration,
      }
    },
    methods: {},
    computed: {
      PermissionService () {
        return PermissionService
      }
    }
  }
</script>

<style scoped>

</style>
