<template>
  <div id="kt_ecommerce_sales_table_wrapper" class="dataTables_wrapper dt-bootstrap4 no-footer">
    <div class="table-responsive">
      <table id="kt_datatable_example_1" class="table align-middle table-row-dashed fs-6 gy-5">
        <thead>
          <tr class="text-start text-gray-400 fw-bold fs-7 text-uppercase gs-0">

            <th v-for="column in columns">{{ column.title }}</th>


          </tr>
        </thead>
        <tbody class="text-gray-600 fw-semibold">
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
  import ApiService, { EnvironmentURL } from '../../core/services/api-service'
  import { APIS } from '../../core/api-urls'

  export default {
    name: 'datatable',
    emits: ['rowClicked'],
    props: ['url', 'columns', 'rows', 'showContextActions', 'tableActions'],
    data () {
      return {
        actions: [{
          action: 'edit',
          class: '.edit'
        }, {
          action: 'delete',
          class: '.delete'
        }],
        datatable: null,
      }
    },
    created () {
      const self = this

      let additionalActions = [];
      if(self.tableActions){
        additionalActions = self.tableActions.map(x => {
          self.actions.push(x);
          return x.html ? x.html : ''
        })
      }
      if (this.showContextActions !== false)
        this.columns.push({
          title: 'Actions',
          data: this.columns[0].data,
          config: {
            className: 'text-end',
            targets: this.columns.length,
            orderable: true,
            render: function (data, type, object) {
              return `<a href="#" class="btn btn-light btn-active-light-primary btn-flex btn-center btn-sm" data-kt-menu="true" data-kt-menu-trigger="click" data-kt-menu-placement="bottom-end">Actions
                <i class="ki-duotone ki-down fs-5 ms-1"></i></a>
              <!--begin::Menu-->
              <div class="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-semibold fs-7 w-125px py-4" data-kt-menu="true">
                <!--begin::Menu item-->
                <div class="menu-item px-3">
                  <a href="#" class="menu-link px-3 edit"  data-bs-toggle="modal" data-bs-target="#kt_modal">Edit</a>
                </div>
                <!--end::Menu item-->
                <!--begin::Menu item-->
                <div class="menu-item px-3">
                  <a href="#" class="menu-link px-3 delete" >Delete</a>
                </div>
                <!--end::Menu item-->
                ${additionalActions.join('')}
              </div>
              <!--end::Menu-->`
            }
          }
        })
    },
    mounted () {
      let self = this
      this.$nextTick(() => {
        var KTDatatablesServerSide = function () {

          let columns = self.columns.map(col => {
            return { data: col.data }
          })
          let definitions = self.columns.map(col => {
            return col.config
          })

          // Shared variables
          let table
          let dt
          let filterPayment

          // Private functions
          var initDatatable = function () {
            dt = $('#kt_datatable_example_1').DataTable({
              searchDelay: 500,
              processing: false,
              serverSide: false,
              order: [[0, 'desc']],
              stateSave: true,
              select: {
                style: 'multi',
                selector: 'td:first-child input[type="checkbox"]',
                className: 'row-selected'
              },
              ajax: {
                dataSrc: '',
                url: EnvironmentURL + APIS[self.url],
                beforeSend: function (request) {
                  request.setRequestHeader('authorization', `Bearer ${self.$store.getters['Auth/token']}`)
                }
              },
              columns: columns,
              columnDefs: definitions,
              // Add data-filter attribute
              createdRow: function (row, data, dataIndex) {
                if (self.actions) {
                  self.actions.forEach((item) => {
                    $(row).find(item.class).on('click', function (e) {
                      self.rowClicked(e, item.action, data)
                    })
                  })
                }
              }
            })

            table = dt.$

            self.datatable = dt

            // Re-init functions on every table re-draw -- more info: https://datatables.net/reference/event/draw
            dt.on('draw', function () {
              KTMenu.createInstances()
            })
          }

          // Search Datatable --- official docs reference: https://datatables.net/reference/api/search()
          /*        var handleSearchDatatable = function () {
           const filterSearch = document.querySelector('[data-kt-docs-table-filter="search"]');
           filterSearch.addEventListener('keyup', function (e) {
           dt.search(e.target.value).draw();
           });
           }*/

          // Filter Datatable
          /*
           var handleFilterDatatable = () => {
           // Select filter options
           filterPayment = document.querySelectorAll('[data-kt-docs-table-filter="payment_type"] [name="payment_type"]');
           const filterButton = document.querySelector('[data-kt-docs-table-filter="filter"]');

           // Filter datatable on submit
           filterButton.addEventListener('click', function () {
           // Get filter values
           let paymentValue = '';

           // Get payment value
           filterPayment.forEach(r => {
           if (r.checked) {
           paymentValue = r.value;
           }

           // Reset payment value if "All" is selected
           if (paymentValue === 'all') {
           paymentValue = '';
           }
           });

           // Filter datatable --- official docs reference: https://datatables.net/reference/api/search()
           dt.search(paymentValue).draw();
           });
           }
           */

          // Reset Filter
          /*        var handleResetForm = () => {
           // Select reset button
           const resetButton = document.querySelector('[data-kt-docs-table-filter="reset"]');

           // Reset datatable
           resetButton.addEventListener('click', function () {
           // Reset payment type
           filterPayment[0].checked = true;

           // Reset datatable --- official docs reference: https://datatables.net/reference/api/search()
           dt.search('').draw();
           });
           }*/

          // Public methods
          return {
            init: function () {
              initDatatable()
              /*          handleSearchDatatable();
               handleFilterDatatable();
               handleResetForm();*/
            }
          }
        }()

        // On document ready
        KTUtil.onDOMContentLoaded(function () {
          KTDatatablesServerSide.init()
        })
      })
    },
    methods: {
      async rowClicked (event, action, object) {
        event.preventDefault()
        this.selectedObject = object
        this.$emit('rowClicked', event, action, object)
      },
      reload () {
        this.datatable.ajax.reload()
      }
    }
  }
</script>

<style scoped>

</style>
