<template>
  <h2>Manage Users</h2>
  <a-p-i-table title="User" api="Id" param="Id" :actions="tableActions" :fields="fields" :fetch-url="'GET_EMPLOYEES'"
               :add-url="'ADD_EMPLOYEE'"
               :edit-url="'UPDATE_EMPLOYEE'" :delete-url="'DELETE_EMPLOYEE'" :columns="columns"
               @onActionClicked="handleRowInteraction">
    <template #selectCustom="props">

      <div class="mb-5">
        <!--begin::Label-->
        <label class="required fw-semibold fs-6 mb-5">Role</label>
        <!--end::Label-->
        <!--begin::Roles-->
        <!--begin::Input row-->
        <template v-for="role in systemRoles">
          <div class="d-flex fv-row">
            <!--begin::Radio-->
            <div class="form-check form-check-custom form-check-solid">
              <input class="form-check-input me-3" type="radio" name="user-role" :value="role.Id"
                     @change="props.onSelect(role.Id)"/>
              <label class="form-check-label" for="kt_modal_update_role_option_0">
                <div class="fw-bold text-gray-800">{{ role.Name }}</div>
                <div class="text-gray-600">{{ role.Description }}</div>
              </label>
              <!--end::Label-->
            </div>
            <!--end::Radio-->
          </div>
          <!--end::Input row-->
          <div class='separator separator-dashed my-5'></div>
        </template>
        <!--end::Roles-->
      </div>

    </template>

  </a-p-i-table>


  <!--  <div class="mb-5">
      &lt;!&ndash;begin::Label&ndash;&gt;
      <label class="required fw-semibold fs-6 mb-5">Role</label>
      &lt;!&ndash;end::Label&ndash;&gt;
      &lt;!&ndash;begin::Roles&ndash;&gt;
      &lt;!&ndash;begin::Input row&ndash;&gt;
      <template v-for="role in systemRoles">
        <div class="d-flex fv-row">
          &lt;!&ndash;begin::Radio&ndash;&gt;
          <div class="form-check form-check-custom form-check-solid">
            &lt;!&ndash;begin::Input&ndash;&gt;
            <input class="form-check-input me-3" type="radio" :value="role.id"  v-model="selectedRole"/>
            &lt;!&ndash;end::Input&ndash;&gt;
            &lt;!&ndash;begin::Label&ndash;&gt;
            <label class="form-check-label" for="kt_modal_update_role_option_0">
              <div class="fw-bold text-gray-800">{{role.name}}</div>
              <div class="text-gray-600">{{role.description}}</div>
            </label>
            &lt;!&ndash;end::Label&ndash;&gt;
          </div>
          &lt;!&ndash;end::Radio&ndash;&gt;
        </div>
        &lt;!&ndash;end::Input row&ndash;&gt;
        <div class='separator separator-dashed my-5'></div>
      </template>
      &lt;!&ndash;end::Roles&ndash;&gt;
    </div>-->
</template>

<script>
  import Datatable from '@/components/tables/datatable.vue'
  import LoadingButton from '@/components/buttons/LoadingButton.vue'
  import APITable from '@/components/tables/APITable.vue'
  import ApiService from '@/core/services/api-service'
  import { APIS } from '@/core/api-urls'

  export default {
    name: 'ManageUsers',
    components: { APITable, LoadingButton, Datatable, },
    emits: ['changed'],
    data () {
      return {
        tableActions: [{
          action: 'reset-password',
          class: '.reset-password',
          html: `<div class="menu-item px-3">
                    <a href="#" class="menu-link px-3 reset-password" >Reset</a>
                </div>`
        }],
        fields: [{
          label: 'Name',
          type: 'input',
          element: 'input',
          placeholder: 'John Doe',
          api: 'Name',
          param: 'Name',
          validate: function (value) {
            return [value.length > 0, 'Name is required.']
          }
        }, {
          label: 'Phone',
          type: 'number',
          element: 'input',
          placeholder: '1234567',
          api: 'Phone',
          param: 'Phone',
        },
          {
            label: 'Email',
            type: 'text',
            element: 'input',
            placeholder: '',
            api: 'Email',
            param: 'Email',
          },
          {
            element: 'select-custom',
            param: 'RolesId',
          },
        ],
        columns: [
          {
            title: 'User',
            data: 'Name',
            config: {
              className: 'd-flex align-items-center',
              targets: 0,
              orderable: true,
              render: function (data, type, object) {
                const names = object.Name.split(' ')
                const fname = names[0]
                const lname = names.length > 1 ? names[1] : ''
                return `<div class="symbol symbol-circle symbol-50px overflow-hidden me-3">
                <a href="#">
                  <div class="symbol-label fs-3 bg-light-danger text-danger">${fname[0]} ${lname !== '' ? lname[0] : ''}</div>
                </a>
              </div>
                <div class="d-flex flex-column">
                <a href="#" class="text-gray-800 text-hover-primary mb-1">${object.Name}</a>
                <span>${object.Email}</span>
              </div>`
              }
            }
          }, {
            title: 'Role',
            data: 'Role',
            config: {
              targets: 1,
              orderable: true,
              render: function (data, type, object) {
                return `${object['Role']['Name']}`
              }
            }
          },
        ],

        systemRoles: []
      }

    },
    async created () {
      this.systemRoles = await ApiService.GenerateGetRequest(APIS.GET_SYSTEM_ROLES)
    },
    mounted () {

    },
    updated () {

    },
    methods: {
      log (val) {
        console.log(val)
        return 'uyo'
      },
      async handleRowInteraction (e, action, data) {
        if (action === 'reset-password') {
          const response = await ApiService.GeneratePostRequest(APIS.PASSWORD_RESET_REQUEST, { EmployeeId: data.Id })
          const { Token } = response.data
          const resetUrl = window.location.origin + '/reset-password/' + Token
          navigator.clipboard.writeText(resetUrl)

          Swal.fire({
            html: `The reset url has been copied to clip board: <br /><br /> <a href="${resetUrl}">${resetUrl}</a>`,
            icon: 'success',
            buttonsStyling: false,
            confirmButtonText: 'Close!',
            customClass: {
              confirmButton: 'btn btn-primary'
            }
          })
        }
      }
    }
  }
</script>

