<template>
  <div class="form-floating border rounded">
    <select
        ref="select2Input"
        class="form-select " :data-placeholder="placeholder">
      <option v-for="item in dataSet" :value="item[code]" :selected="item[code] === selectedValue">{{ item[value] }}
      </option>
    </select>
    <label>{{ label }}</label>
  </div>
</template>

<script>
import ApiService from "../../core/services/api-service";
import {APIS} from "../../core/api-urls";

export default {
  name: "select2",
  emits: ["valueChanged"],
  props: ["url", "placeholder", "code", "value", "selectedValue", "dynamicCreation", "label", "disabled"],
  data() {
    return {
      dataSet: [],
    }
  },
  watch: {
    selectedValue(newVal, oldVal) {
      if (newVal !== oldVal) {
        $(this.$refs.select2Input).val(newVal).trigger('change');
      }

    }
  },
  async created() {
    let response = await ApiService.GenerateGetRequest(APIS[this.url]);
    if (response.status === 200) {
      this.dataSet = response.data;
    }

    this.$nextTick(() => {
      this.init();
      $(this.$refs.select2Input).on("change", () => {
        let selected = $(this.$refs.select2Input).val();
        let object = this.dataSet.find(x => x.Id === parseInt(selected));

        this.$emit('valueChanged', $(this.$refs.select2Input).val(), object ? object[this.value] : null);
      });
    });
  },
  methods: {
    init() {
      $(this.$refs.select2Input).select2({
        tags: this.dynamicCreation,
        disabled: this.disabled || false
      });
    },
    async refresh() {
      $(this.$refs.select2Input).select2("destroy");
      let response = await ApiService.GenerateGetRequest(APIS[this.url]);
      if (response.status === 200) {
        this.dataSet = response.data;
        this.$nextTick(() => {
          $(this.$refs.select2Input).find("option").each((idx, item) => {
            if (isNaN($(item).val()))
              $(item).remove();
          });
          this.init();
        });
      }
    }
  }

}
</script>

<style scoped>

</style>
