<template>
  <h2>Manage Companies</h2>

  <a-p-i-table title="Company" api="Id" param="Id" :fields="fields" :fetch-url="'GET_COMPANIES'" :add-url="'ADD_COMPANY'" :edit-url="'UPDATE_COMPANY'"  :delete-url="'DELETE_COMPANIES'" :columns="columns"/>
</template>

<script>
  import ApiService from '@/core/services/api-service'
  import { APIS } from '@/core/api-urls'
  import Datatable from '@/components/tables/datatable.vue'
  import LoadingButton from '@/components/buttons/LoadingButton.vue'
  import APITable from '@/components/tables/APITable.vue'

  export default {
    name: 'ManageCompanies',
    components: { APITable, LoadingButton, Datatable,  },
    data () {
      return {
        data: [],
        systemRoles:[],

        fields: [{
          label: 'Name',
          type: 'input',
          element: 'input',
          placeholder: 'Company A',
          api: "Name",
          param: 'Name',
          validate: function (value){
            return [value.length > 0, "Name is required."];
          }
        },{
          label: 'Phone',
          type: 'number',
          element: 'input',
          placeholder: '1234567',
          api: "Phone",
          param: 'Phone',

        },{
          label: 'Address',
          type: 'text',
          element: 'input',
          placeholder: '',
          api: "Address",
          param: 'Address',
        }],
        columns: [
          {
            title: 'Name',
            data: 'Name',
            config: {
              className:'d-flex align-items-center',
              targets: 0,
              orderable: true,
              render: function (data, type, object) {
                return data;
              }
            }
          }, {
            title: 'Phone',
            data: 'Phone',
            config: {
              targets: 1,
              orderable: true,
              render: function (data, type, object) {
                return data;
              }
            }
          }, {
            title: 'Address',
            data: 'Address',
            config: {
              targets:2,
              orderable: true,
              render: function (data, type, object) {
                return data;
              }
            }
          },
        ],



      }

    },
    async created () {
      this.data = await ApiService.GenerateGetRequest(APIS.GET_COMPANIES);
    },
    mounted () {

    },
    updated () {

    },
    methods: {
      async handleRowInteraction(e, action, data){
        if(action === "delete"){

        }


      }
    }
  }
</script>

