export const PERMISSION_PAGES = {
	DEFAULT: 'default',
	DASHBOARD: "Dashboard",
	DELINQUENCY: "Delinquency",
	ONBOARDING: "Onboarding",
	OPERATIONS: "Operations",
	FINANCE: "Finance",
	FINANCE_INVOICE: "FinanceInvoice",
	FINANCE_RECEIPTS: "FinanceReceipts",
	FINANCE_EXPENSE_REPORTS: "FinanceExpenseReports",
	FINANCE_PAYMENT_PROCESSING: "FinancePaymentProcessing",
	DOCUMENTS: "Documents",
	DOCUMENT_RECENT_SCANS: "DocumentRecentScans",
	DOCUMENT_BULK_UPLOADS: "DocumentBulkUploads",
	DOCUMENT_BATCH_PROCESSING: "DocumentBatchProcessing",
	DOCUMENT_OPERATIONS: "DocumentOperations",
	MANAGEMENT: "Management",
	MANAGE_COMPANIES: "ManageCompanies",
	MANAGE_ROLE_PERMISSIONS: "ManageRolePermissions",
	MANAGE_ROLES: "ManageRoles",
	MANAGE_USERS: "ManageUsers",
	REPORTS: "Reports",
	REPORT_DOCUMENT: "ReportDocument",
	REPORT_USER: "ReportUser",
	REPORT_SYSTEM: "ReportSystem",
}
export const PERMISSION_ACTIONS = {
	DEFAULT: 'default',
	CAN_VIEW: "view",
	CAN_CREATE: "create",
	CAN_EDIT: "edit",
	CAN_DELETE: "delete",
}