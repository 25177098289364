<template>
  <!--begin::Row-->
  <div class="row g-5 g-xl-10 mb-5 mb-xl-10" >
    <!--begin::Col-->
<!--    <div class="col-md-6 col-lg-6 col-xl-6 col-xxl-3 mb-md-5 mb-xl-10">
      &lt;!&ndash;begin::Card widget 20&ndash;&gt;
      <div class="card card-flush bgi-no-repeat bgi-size-contain bgi-position-x-end h-md-50 mb-5 mb-xl-10" style="background-color: #F1416C;background-image:url('assets/media/patterns/vector-1.png')">
        &lt;!&ndash;begin::Header&ndash;&gt;
        <div class="card-header pt-5">
          &lt;!&ndash;begin::Title&ndash;&gt;
          <div class="card-title d-flex flex-column">
            &lt;!&ndash;begin::Amount&ndash;&gt;
            <span class="fs-2hx fw-bold text-white me-2 lh-1 ls-n2">0</span>
            &lt;!&ndash;end::Amount&ndash;&gt;
            &lt;!&ndash;begin::Subtitle&ndash;&gt;
            <span class="text-white opacity-75 pt-1 fw-semibold fs-6">Documents Processing</span>
            &lt;!&ndash;end::Subtitle&ndash;&gt;
          </div>
          &lt;!&ndash;end::Title&ndash;&gt;
        </div>
        &lt;!&ndash;end::Header&ndash;&gt;
        &lt;!&ndash;begin::Card body&ndash;&gt;
        <div class="card-body d-flex align-items-end pt-0">
          &lt;!&ndash;begin::Progress&ndash;&gt;
          <div class="d-flex align-items-center flex-column mt-3 w-100">
            <div class="d-flex justify-content-between fw-bold fs-6 text-white opacity-75 w-100 mt-auto mb-2">
              <span>0 Pending</span>
              <span>0%</span>
            </div>
            <div class="h-8px mx-3 w-100 bg-white bg-opacity-50 rounded">
              <div class="bg-white rounded h-8px" role="progressbar" style="width: 0%;" aria-valuenow="50" aria-valuemin="0" aria-valuemax="100"></div>
            </div>
          </div>
          &lt;!&ndash;end::Progress&ndash;&gt;
        </div>
        &lt;!&ndash;end::Card body&ndash;&gt;
      </div>
      &lt;!&ndash;end::Card widget 20&ndash;&gt;
    </div>
    &lt;!&ndash;end::Col&ndash;&gt;
    &lt;!&ndash;begin::Col&ndash;&gt;
    <div class="col-md-6 col-lg-6 col-xl-6 col-xxl-3 mb-md-5 mb-xl-10">
      &lt;!&ndash;begin::Card widget 17&ndash;&gt;
      <div class="card card-flush h-md-50 mb-5 mb-xl-10">
        &lt;!&ndash;begin::Header&ndash;&gt;
        <div class="card-header pt-5">
          &lt;!&ndash;begin::Title&ndash;&gt;
          <div class="card-title d-flex flex-column">
            &lt;!&ndash;begin::Info&ndash;&gt;
            <div class="d-flex align-items-center">
              &lt;!&ndash;begin::Currency&ndash;&gt;
              <span class="fs-4 fw-semibold text-gray-400 me-1 align-self-start"></span>
              &lt;!&ndash;end::Currency&ndash;&gt;
              &lt;!&ndash;begin::Amount&ndash;&gt;
              <span class="fs-2hx fw-bold text-dark me-2 lh-1 ls-n2">0</span>
              &lt;!&ndash;end::Amount&ndash;&gt;
              &lt;!&ndash;begin::Badge&ndash;&gt;
              &lt;!&ndash;end::Badge&ndash;&gt;
            </div>
            &lt;!&ndash;end::Info&ndash;&gt;
            &lt;!&ndash;begin::Subtitle&ndash;&gt;
            <span class="text-gray-400 pt-1 fw-semibold fs-6">Documents Processed Today</span>
            &lt;!&ndash;end::Subtitle&ndash;&gt;
          </div>
          &lt;!&ndash;end::Title&ndash;&gt;
        </div>
        &lt;!&ndash;end::Header&ndash;&gt;
        &lt;!&ndash;begin::Card body&ndash;&gt;
        <div class="card-body pt-2 pb-4 d-flex flex-wrap align-items-center">
          &lt;!&ndash;begin::Chart&ndash;&gt;

          &lt;!&ndash;end::Chart&ndash;&gt;
          &lt;!&ndash;begin::Labels&ndash;&gt;
          <div class="d-flex flex-column content-justify-center flex-row-fluid">
            &lt;!&ndash;begin::Label&ndash;&gt;
            <div class="d-flex fw-semibold align-items-center">
              &lt;!&ndash;begin::Bullet&ndash;&gt;
              <div class="bullet w-8px h-3px rounded-2 bg-success me-3"></div>
              &lt;!&ndash;end::Bullet&ndash;&gt;
              &lt;!&ndash;begin::Label&ndash;&gt;
              <div class="text-gray-500 flex-grow-1 me-4">Tick Over</div>
              &lt;!&ndash;end::Label&ndash;&gt;
              &lt;!&ndash;begin::Stats&ndash;&gt;
              <div class="fw-bolder text-gray-700 text-xxl-end">0</div>
              &lt;!&ndash;end::Stats&ndash;&gt;
            </div>
            &lt;!&ndash;end::Label&ndash;&gt;
            &lt;!&ndash;begin::Label&ndash;&gt;
            <div class="d-flex fw-semibold align-items-center my-3">
              &lt;!&ndash;begin::Bullet&ndash;&gt;
              <div class="bullet w-8px h-3px rounded-2 bg-primary me-3"></div>
              &lt;!&ndash;end::Bullet&ndash;&gt;
              &lt;!&ndash;begin::Label&ndash;&gt;
              <div class="text-gray-500 flex-grow-1 me-4">Cheques</div>
              &lt;!&ndash;end::Label&ndash;&gt;
              &lt;!&ndash;begin::Stats&ndash;&gt;
              <div class="fw-bolder text-gray-700 text-xxl-end">0</div>
              &lt;!&ndash;end::Stats&ndash;&gt;
            </div>
            &lt;!&ndash;end::Label&ndash;&gt;
            &lt;!&ndash;begin::Label&ndash;&gt;
            <div class="d-flex fw-semibold align-items-center">
              &lt;!&ndash;begin::Bullet&ndash;&gt;
              <div class="bullet w-8px h-3px rounded-2 me-3" style="background-color: #E4E6EF"></div>
              &lt;!&ndash;end::Bullet&ndash;&gt;
              &lt;!&ndash;begin::Label&ndash;&gt;
              <div class="text-gray-500 flex-grow-1 me-4">Others</div>
              &lt;!&ndash;end::Label&ndash;&gt;
              &lt;!&ndash;begin::Stats&ndash;&gt;
              <div class="fw-bolder text-gray-700 text-xxl-end">0</div>
              &lt;!&ndash;end::Stats&ndash;&gt;
            </div>
            &lt;!&ndash;end::Label&ndash;&gt;
          </div>
          &lt;!&ndash;end::Labels&ndash;&gt;
        </div>
        &lt;!&ndash;end::Card body&ndash;&gt;
      </div>
      &lt;!&ndash;end::Card widget 17&ndash;&gt;
      &lt;!&ndash;begin::List widget 26&ndash;&gt;
      &lt;!&ndash;end::LIst widget 26&ndash;&gt;
    </div>-->
    <!--end::Col-->
    <!--begin::Col-->
    <div class="col-xxl-12">
      <!--begin::Engage widget 10-->
      <div class="card card-flush h-md-100">
        <!--begin::Body-->
        <div class="card-body d-flex flex-column justify-content-between mt-9 bgi-no-repeat bgi-size-cover bgi-position-x-center pb-0" style="background-position: 100% 50%; background-image:url('assets/media/stock/900x600/42.png')">
          <!--begin::Wrapper-->
          <div class="mb-10">
            <!--begin::Title-->
            <div class="fs-2hx fw-bold text-gray-800 text-center mb-13">
                <span class="me-2">No Data Available
                <br />
                </span>
            </div>
            <!--end::Title-->
            <!--begin::Action-->
            <!--begin::Action-->
          </div>
          <!--begin::Wrapper-->
          <!--begin::Illustration-->
          <img class="mx-auto h-150px h-lg-200px theme-light-show" src="assets/media/illustrations/misc/upgrade.svg" alt="" />
          <img class="mx-auto h-150px h-lg-200px theme-dark-show" src="assets/media/illustrations/misc/upgrade-dark.svg" alt="" />
          <!--end::Illustration-->
        </div>
        <!--end::Body-->
      </div>
      <!--end::Engage widget 10-->
    </div>
    <!--end::Col-->
  </div>
</template>

<script>
// @ is an alias to /src

export default {
  name: 'Dashboard',
  components: {},
  created() {

  }
}
</script>
