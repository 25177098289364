<template>
  <div class="card card-flush">
    <!--begin::Card header-->
    <div class="card-header py-5 gap-2 gap-md-5">
      <!--begin::Card title-->
      <div class="card-title">
        <div class="d-flex align-items-center position-relative my-1">
          <!--begin::Search-->
          <div class="d-flex align-items-center position-relative my-1 mb-2 mb-md-0">
            <!--begin::Svg Icon | path: icons/duotune/general/gen021.svg-->
            <span class="svg-icon svg-icon-1 position-absolute ms-6">
														<svg width="24" height="24" viewBox="0 0 24 24" fill="none"
                                 xmlns="http://www.w3.org/2000/svg">
															<rect opacity="0.5" x="17.0365" y="15.1223" width="8.15546" height="2" rx="1"
                                    transform="rotate(45 17.0365 15.1223)" fill="currentColor"></rect>
															<path
                                  d="M11 19C6.55556 19 3 15.4444 3 11C3 6.55556 6.55556 3 11 3C15.4444 3 19 6.55556 19 11C19 15.4444 15.4444 19 11 19ZM11 5C7.53333 5 5 7.53333 5 11C5 14.4667 7.53333 17 11 17C14.4667 17 17 14.4667 17 11C17 7.53333 14.4667 5 11 5Z"
                                  fill="currentColor"></path>
														</svg>
													</span>
            <!--end::Svg Icon-->
            <input type="text" data-kt-docs-table-filter="search" class="form-control form-control-solid w-250px ps-15"
                   placeholder="Search Companies">
          </div>
          <!--end::Search-->

          <!--begin::Toolbar-->
          <div class="d-flex justify-content-end" data-kt-docs-table-toolbar="base">
            <!--begin::Filter-->
            <button type="button" class="btn btn-light-primary me-3" data-kt-menu-trigger="click"
                    data-kt-menu-placement="bottom-end">
              <!--begin::Svg Icon | path: icons/duotune/general/gen031.svg-->
              <span class="svg-icon svg-icon-2">
														<svg width="24" height="24" viewBox="0 0 24 24" fill="none"
                                 xmlns="http://www.w3.org/2000/svg">
															<path
                                  d="M19.0759 3H4.72777C3.95892 3 3.47768 3.83148 3.86067 4.49814L8.56967 12.6949C9.17923 13.7559 9.5 14.9582 9.5 16.1819V19.5072C9.5 20.2189 10.2223 20.7028 10.8805 20.432L13.8805 19.1977C14.2553 19.0435 14.5 18.6783 14.5 18.273V13.8372C14.5 12.8089 14.8171 11.8056 15.408 10.964L19.8943 4.57465C20.3596 3.912 19.8856 3 19.0759 3Z"
                                  fill="currentColor"></path>
														</svg>
													</span>
              <!--end::Svg Icon-->Filter
            </button>
            <!--begin::Menu 1-->
            <div class="menu menu-sub menu-sub-dropdown w-300px w-md-325px" data-kt-menu="true" id="kt-toolbar-filter">
              <!--begin::Header-->
              <div class="px-7 py-5">
                <div class="fs-4 text-dark fw-bold">Filter Options</div>
              </div>
              <!--end::Header-->
              <!--begin::Separator-->
              <div class="separator border-gray-200"></div>
              <!--end::Separator-->
              <!--begin::Content-->
              <div class="px-7 py-5">
                <!--begin::Input group-->
                <div class="mb-10">
                  <!--begin::Label-->
                  <label class="form-label fs-5 fw-semibold mb-3">Payment Type:</label>
                  <!--end::Label-->
                  <!--begin::Options-->
                  <div class="d-flex flex-column flex-wrap fw-semibold" data-kt-docs-table-filter="payment_type">
                    <!--begin::Option-->
                    <label class="form-check form-check-sm form-check-custom form-check-solid mb-3 me-5">
                      <input class="form-check-input" type="radio" name="payment_type" value="all" checked="checked">
                      <span class="form-check-label text-gray-600">All</span>
                    </label>
                    <!--end::Option-->
                    <!--begin::Option-->
                    <label class="form-check form-check-sm form-check-custom form-check-solid mb-3 me-5">
                      <input class="form-check-input" type="radio" name="payment_type" value="visa">
                      <span class="form-check-label text-gray-600">Receipt</span>
                    </label>
                    <!--end::Option-->
                    <!--begin::Option-->
                    <label class="form-check form-check-sm form-check-custom form-check-solid mb-3">
                      <input class="form-check-input" type="radio" name="payment_type" value="mastercard">
                      <span class="form-check-label text-gray-600">Invoice</span>
                    </label>
                    <!--end::Option-->
                  </div>
                  <!--end::Options-->
                </div>
                <!--end::Input group-->
                <!--begin::Actions-->
                <div class="d-flex justify-content-end">
                  <button type="reset" class="btn btn-light btn-active-light-primary me-2" data-kt-menu-dismiss="true"
                          data-kt-docs-table-filter="reset">Reset
                  </button>
                  <button type="submit" class="btn btn-primary" data-kt-menu-dismiss="true"
                          data-kt-docs-table-filter="filter">Apply
                  </button>
                </div>
                <!--end::Actions-->
              </div>
              <!--end::Content-->
            </div>
            <!--end::Menu 1-->
            <!--end::Filter-->
            <!--begin::Add customer-->
            <button type="button" class="btn btn-primary" data-bs-toggle="tooltip" data-bs-original-title="Coming Soon"
                    data-kt-initialized="1">
              <!--begin::Svg Icon | path: icons/duotune/arrows/arr075.svg-->
              <span class="svg-icon svg-icon-2">
														<svg width="24" height="24" viewBox="0 0 24 24" fill="none"
                                 xmlns="http://www.w3.org/2000/svg">
															<rect opacity="0.5" x="11.364" y="20.364" width="16" height="2" rx="1"
                                    transform="rotate(-90 11.364 20.364)" fill="currentColor"></rect>
															<rect x="4.36396" y="11.364" width="16" height="2" rx="1" fill="currentColor"></rect>
														</svg>
													</span>
              <!--end::Svg Icon-->Add Expense
            </button>
            <!--end::Add customer-->
          </div>
          <!--end::Toolbar-->
        </div>
      </div>
      <!--end::Card title-->
    </div>
    <!--end::Card header-->
    <!--begin::Card body-->
    <div class="card-body pt-0  ">
      <!--begin::Table-->
      <datatable ref="dataTable" :url="'GET_CUSTOMER_SCAN_LIST'" @rowClicked="companyNameClicked" :columns="expensesColumns"/>
      <!--end::Table-->
    </div>
    <!--end::Card body-->
  </div>

  <teleport to="body">
    <slide-in-panel :title="selectedObject ? selectedObject.CompanyName : 'Scan Details'" :object="selectedObject"
                    :itemKey="'row_details'"
                    :drawerOn="showDrawer" @sliderClosed="showDrawer = false">
      <div style="flex: 1">
        <form action="" v-if="selectedObject !== null">
          <div class="row row-cols-1 row-cols-sm-2 rol-cols-md-2 row-cols-lg-2 mb-3 ">
            <!--begin::Col-->
            <div class="col">
              <!--begin::Input group-->
              <select2 ref="merchantSelect2"
                       placeholder="Select Merchant"
                       value="Name"
                       code="Id"
                       :url="'LOOK_UP_CUSTOMER_MERCHANTS'"
                       :selectedValue="selectedObject.CompanyCode"
                       :dynamicCreation="true"
                       label="Merchant"
                       @valueChanged="handleMerchantChangedUpdate"/>
            </div>
            <!--end::Col-->

            <!--begin::Col-->
            <div class="col">
              <!--begin::Input group-->
              <select2 ref="categorySelect2"
                       placeholder="Select Category"
                       value="Description"
                       code="Id"
                       :url="'LOOK_UP_CATEGORIES'"
                       :selectedValue="selectedObject.CategoryCode"
                       :dynamicCreation="true"
                       label="Category"
                       @valueChanged="handleCategoryChangedUpdate"/>

            </div>
          </div>
          <div class="row row-cols-1 row-cols-sm-1 rol-cols-md-2 row-cols-lg-2 mb-3 ">
            <!--begin::Col-->
            <div class="col">

              <!--begin::Input group-->
              <div class=" form-floating">
                <input type="date" class="form-control" id="transaction_date" placeholder=""
                       v-model="selectedObject.TransactionDate"/>
                <label for="transaction_date">Transaction Date</label>
                <!--end::Input group-->
              </div>
            </div>
            <!--end::Col-->
            <!--begin::Col-->
            <div class="col">
              <!--begin::Input group-->
              <div class="  input-group ">
                <span class="input-group-text">$</span>
                <div class="form-floating">
                  <input type="number" class="form-control" id="total" step=".01" placeholder=""
                         v-model="selectedObject.Total"/>
                  <label for="total">Total</label>
                </div>
                <!--end::Input group-->
              </div>
            </div>
            <!--end::Col-->
          </div>

          <div class="row row-cols-1 row-cols-sm-1 rol-cols-md-1 row-cols-lg-1 mb-3 ">
            <div class="col">
              <!--begin::Input group-->
              <div class=" form-floating">
                <div class="form-floating">
                  <textarea class="form-control" rows="2" placeholder="Leave a comment here" id="notes"
                            v-model="selectedObject.Notes"></textarea>
                  <label for="notes">Notes</label>
                </div>
              </div>
            </div>
            <!--end::Col-->
          </div>

          <div class="row row-cols-1" v-if="selectedObjectTableData.length > 0">
            <div class="table-responsive" style="max-height: 250px;    overflow: auto;">
              <!--begin::Table-->
              <table class="table align-middle gs-0 gy-4 border table-row-dashed  table-hover">
                <!--begin::Table head-->
                <thead>
                <tr class="fw-bold text-muted bg-light">
                  <th class="ps-4 min-w-125px rounded-start ps-6">Item</th>
                  <th class="min-w-125px">Total Paid</th>
                </tr>
                </thead>
                <!--end::Table head-->
                <!--begin::Table body-->
                <tbody>
                <template v-for="(item, index) in selectedObjectTableData">
                  <tr>
                    <td>
                      <input class="form-control text-dark   d-block mb-1 fs-6 "
                             :value="item.Name"
                             @keyup="nameChanged($event, index)"
                             type="text"/>
                    </td>


                    <td>
                      <div class="input-group mb-1">
                        <span class="input-group-text">$</span>
                        <input class="form-control text-dark   d-block fs-6 "
                               @keyup="priceChanged($event, index)"
                               @change="priceChanged($event, index)"
                               :value="item.Price" step=".01" type="number"/>
                      </div>
                    </td>
                  </tr>
                </template>
                </tbody>
                <!--end::Table body-->
              </table>
              <!--end::Table-->
            </div>
          </div>


          <div class="row row-cols-1 mt-7 d-none" v-if="false">
            <h3 class="fs-6">Scanned Images</h3>
            <div class="d-flex flex-wrap gap-1 justify-content-start" style="height: 140px">
              <a v-for="image in selectedObject.Images.split(',')" href="https://place-hold.it/124x124" target="_blank">
                <div class="position-relative" style="height: 100px">
                <span class="svg-icon svg-icon-muted svg-icon-4hx position-absolute top-50 start-50 translate-middle"
                      style="color: #DDD">
                  <svg width="24" height="24" viewBox="0 0 24 24"
                       fill="none" xmlns="http://www.w3.org/2000/svg">
                      <rect opacity="0.5" x="16.9497" y="8.46448" width="13" height="2" rx="1"
                            transform="rotate(135 16.9497 8.46448)"
                            fill="currentColor"/>
                      <path
                          d="M14.8284 9.97157L14.8284 15.8891C14.8284 16.4749 15.3033 16.9497 15.8891 16.9497C16.4749 16.9497 16.9497 16.4749 16.9497 15.8891L16.9497 8.05025C16.9497 7.49797 16.502 7.05025 15.9497 7.05025L8.11091 7.05025C7.52512 7.05025 7.05025 7.52513 7.05025 8.11091C7.05025 8.6967 7.52512 9.17157 8.11091 9.17157L14.0284 9.17157C14.4703 9.17157 14.8284 9.52975 14.8284 9.97157Z"
                          fill="currentColor"/>
                      </svg>
                      </span>
                  <document-thumbnail :name="image.split('/')[2]"
                                      :src="'https://place-hold.it/124x124'"></document-thumbnail>

                </div>
              </a>
            </div>
          </div>
          <div class="row row-cols-2 row-cols-sm-2 rol-cols-md-2 row-cols-lg-2 mt-7 mb-7">
            <div class="col">
              <span>Parsed On <strong>{{ formatDefault(selectedObject.DateParsed) }}</strong></span>
            </div>
            <div class="col justify-content-end d-flex">
              <span>Scanned On <strong>{{ formatDefault(selectedObject.DateScanned) }}</strong></span>
            </div>
          </div>
          <div class="row row-cols-1">
            <loading-button @click.prevent="updateRecord" :text="'Update'" :active-text="'Updating'"
                            :active="isUpdating"/>
          </div>
        </form>
      </div>
    </slide-in-panel>
  </teleport>
</template>

<script>
import ApiService, {EnvironmentURL} from "../../core/services/api-service";
import {APIS} from "../../core/api-urls";
import SlideInPanel from "../../components/SlideInPanel.vue";


//MIXINS
import dateFormatter from "../../mixins/date-formatter";
import DocumentThumbnail from "../../components/documents/document-thumbnail.vue";
import LoadingButton from "../../components/buttons/LoadingButton.vue";
import Select2 from "../../components/inputs/select2.vue";
import {parse} from "@vue/compiler-sfc";
import Datatable from "../../components/tables/datatable.vue";

export default {
  name: "ManageExpenses",
  components: {Datatable, Select2, LoadingButton, DocumentThumbnail, SlideInPanel},
  mixins: [dateFormatter],
  data() {
    return {
      isUpdating: false,
      selectedObject: null,
      selectedObjectTableData: [],
      showDrawer: false,
      scanList: [],

      expensesColumns: [
        {
          title: 'Company Name',
          data: 'CompanyName',
          config: {
            targets: 0,
            orderable: true,
            render: function (data, type, object) {
              let string = data;
              let category = object.CategoryDescription;
              if (string === "")
                string = "Not Parsed & Imported";
              return `<a class="text-gray-800 text-hover-primary fw-bold " href="#">${string} <span class="badge badge-secondary">${category}</span></a>`;

            }
          }
        },
        {
          title: 'Type',
          data: 'ScanTypeDescription',
          config: {}
        },
        {
          title: 'Parsed',
          data: 'IsParsed',
          config: {
            targets: 2,
            orderable: false,
            render: function (data) {
              data = data === 1 ? "Yes" : "No";
              return `${data}`;
            }

          }
        },
        {
          title: 'Imported',
          data: 'IsImported',
          config: {
            targets: 3,
            orderable: false,
            render: function (data) {
              data = data === 1 ? "Yes" : "No";
              return `${data}`;
            }
          }
        },
        {
          title: 'Transaction Date',
          data: 'TransactionDate',
          config: {}
        },
        {
          title: 'Notes',
          data: 'Notes',
          config: {}
        },
        {
          title: 'Total',
          data: 'Total',
          config: {
            targets: 6,
            orderable: true,
            render: function (data) {
              data = data ? "$" + data : "";
              return `${data}`;
            }
          }
        },
      ],
      loaded: false,
    }
  },
  async created() {

  },
  mounted() {

  },
  methods: {
    async companyNameClicked(event, object) {
      this.selectedObject = object;
      this.selectedObjectTableData = [];

      this.showDrawer = true;
      let response = await ApiService.GenerateGetRequest(APIS.GET_CUSTOMER_SCAN_ITEMS, {
        si: object.Id
      });
      if (response.status === 200)
        this.selectedObjectTableData = response.data;
    },
    nameChanged(event, index) {
      this.selectedObjectTableData[index].SuggestedName = event.target.value;
    },
    priceChanged(event, index) {
      this.selectedObjectTableData[index].SuggestedPrice = event.target.value;
    },
    async updateRecord() {
      if (this.isUpdating)
        return;
      this.isUpdating = true;
      this.selectedObject.Items = this.selectedObjectTableData;
      let response = await ApiService.GeneratePostRequest(APIS.UPDATE_CUSTOMER_SCAN, {
        j: this.selectedObject
      });

      if (response.status === 200) {

        let data = response.data;
        if (isNaN(parseInt(this.selectedObject.CompanyCode)))
          await this.$refs.merchantSelect2.refresh();

        if (isNaN(parseInt(this.selectedObject.CategoryCode)))
          await this.$refs.categorySelect2.refresh();

        this.handleCategoryChangedUpdate(data.CategoryId);
        this.handleMerchantChangedUpdate(data.MerchantId);
        this.selectedObject.Total = data.Total;
        this.selectedObject.Notes = data.Notes;
        this.selectedObject.TransactionDate = data.TransactionDate;

        this.$refs.dataTable.reload();
      }

      this.isUpdating = false;
    },
    handleCategoryChangedUpdate(newValue) {
      this.selectedObject.CategoryCode = newValue;
    },
    handleMerchantChangedUpdate(newValue) {
      this.selectedObject.CompanyCode = newValue;
    }
  }
}
</script>

<style>

</style>
