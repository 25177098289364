import {createRouter, createWebHistory} from 'vue-router'
import Home from '../views/Dashboard.vue'
import MainLayout from "../views/layouts/MainLayout";
import SignIn from "../views/auth/SignIn.vue";
import AnalyseDocument from "../views/finances/ImportExpenses.vue";

import store from "../store/index";
import Products from "../views/finances/Products.vue";
import ManageExpenses from "../views/finances/ManageExpenses.vue";
import OnboardCompany from "@/views/onboarding/OnboardCompany.vue";
import ManageUsers from '@/views/management/ManageUsers.vue'
import ManageRoles from '@/views/management/ManageRoles.vue'
import ManageRolePermissions from '@/views/management/ManageRolePermissions.vue'
import ManageCompanies from '@/views/management/ManageCompanies.vue'
import Test from '@/views/test.vue'
import ResetPassword from '@/views/auth/ResetPassword.vue'

const routes = [
	{
		// authenticated routes
		path: "/sign-in",
		component: SignIn,
	},	{
		// authenticated routes
		path: "/reset-password/:token",
		component: ResetPassword,
	},
	{
		// authenticated routes
		path: "/",
		component: MainLayout,
		children: [{
			path: "/",
			name: "Home",
			component: Home,
		}, {
			path: "/onboard-company",
			name: "OnboardCompany",
			component: OnboardCompany,
		}, {
			path: "/finances/import",
			name: "Analyse",
			component: AnalyseDocument,
		}, {
			path: "/finances/manage",
			name: "ManageExpenses",
			component: ManageExpenses,
		}, {
			path: "/finances/products",
			name: "Products",
			component: Products,
		}, {
			path: "/manage/users",
			name: "ManageUsers",
			component: ManageUsers,
		}, {
			path: "/manage/roles",
			name: "ManageRoles",
			component: ManageRoles,
		}, {
			path: "/manage/role-permissions",
			name: "ManageRolePermissions",
			component: ManageRolePermissions,
		}, {
			path: "/manage/companies",
			name: "ManageCompanies",
			component: ManageCompanies,
		}, {
			path: "/test",
			name: "Test",
			component: Test,
		}],
		meta: {requiresAuth: true}
	},
]

const router = createRouter({
	                            history: createWebHistory(process.env.BASE_URL),
	                            routes
                            })


router.beforeEach((to, from) => {
	// redirect to login if not authenticated
	if (to.meta["requiresAuth"] && store.state.Auth.token === null) {
		return {
			path: '/sign-in',
			// save the location we were at to come back later
			query: {redirect: to.fullPath},
		};
	}
	
	// redirect to dashboard if logged in
	if (store.state.Auth.token !== null && to.path === "/sign-in") {
		let location = to.query["redirect"] || "/";
		return {
			path: location,
		};
	}
});
export default router
