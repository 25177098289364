const decodeToken = (token) => {
	return decodeURIComponent(atob(token.replace(/_/g, '/').replace(/-/g, '+')).split('').map(function (c) {
		return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
	}).join(''));
}


class JWTService {
	static decodeJWT(token) {
		const [headerB64, payloadB64] = token.split('.');
		
		const header = JSON.parse(decodeToken(headerB64));
		const data = JSON.parse(decodeToken(payloadB64));
		
		// Note: Signature cannot be decoded, as it's a hash.
		return {
			header,
			data
		};
	}
}


export default JWTService;