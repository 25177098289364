const dateFormatter = {
    methods: {
        formatDefault(dateString) {
            return moment(dateString).format("Do MMM YYYY")
        }
    },
    // etc.
};


export default dateFormatter;

