<template>
  <h2>Manage Roles</h2>

  <a-p-i-table title="Role" api="Id" param="Id" :fields="fields" :fetch-url="'GET_SYSTEM_ROLES'" :add-url="'ADD_ROLES'" :edit-url="'UPDATE_ROLES'" :delete-url="'DELETE_ROLES'" :columns="columns"/>


</template>

<script>
  import ApiService from '@/core/services/api-service'
  import { APIS } from '@/core/api-urls'
  import Datatable from '@/components/tables/datatable.vue'
  import LoadingButton from '@/components/buttons/LoadingButton.vue'
  import APITable from '@/components/tables/APITable.vue'

  export default {
    name: 'ManageRoles',
    components: { APITable, LoadingButton, Datatable,  },
    data () {
      return {
        fields: [{
          label: 'Name',
          type: 'input',
          element: 'input',
          placeholder: 'Admin',
          api: "Name",
          param: 'Name',
          validate: function (value){
            return [value.length > 0, "Name is required."];
          }
        },{
          label: 'Description',
          type: 'input',
          element: 'input',
          placeholder: 'Role Description',
          api: "Description",
          param: 'Description',
          validate: function (value){
            return [value.length > 0, "Description is required."];
          }
        },],
        columns: [
          {
            title: 'Role Name',
            data: 'Name',
            config: {
              className:'d-flex align-items-center',
              targets: 0,
              orderable: true,
              render: function (data, type, object) {
                return data;
              }
            }
          }, {
            title: 'Description',
            data: 'Description',
            config: {
              targets: 1,
              orderable: true,
              render: function (data, type, object) {
                return data;
              }
            }
          },
        ],

      }

    },
    async created () {
    },
    mounted () {

    },
    updated () {

    },
    methods: {
      async handleRowInteraction(e, action, data){
        if(action === "delete"){
        }
      }
    }
  }
</script>

