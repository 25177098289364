<template>
  <div class="d-flex flex-column flex-root" id="kt_app_root">
    <!--begin::Authentication - Sign-in -->
    <div class="d-flex flex-column flex-lg-row flex-column-fluid">
      <!--begin::Logo-->
      <a href="#" class="d-block d-lg-none mx-auto py-20">
        <img alt="Logo" src="assets/media/logos/default.svg" class="theme-light-show h-25px">
        <img alt="Logo" src="assets/media/logos/default-dark.svg" class="theme-dark-show h-25px">
      </a>
      <!--end::Logo-->
      <!--begin::Aside-->
      <div class="d-flex flex-column flex-column-fluid flex-center w-lg-50 p-10">
        <!--begin::Wrapper-->
        <div class="d-flex justify-content-center flex-column-fluid flex-column w-100 mw-450px">
          <!--begin::Header-->
          <div class="d-flex flex-stack py-2">
            <!--begin::Back link-->
            <div class="me-2"></div>
            <!--end::Back link-->
          </div>
          <!--end::Header-->
          <!--begin::Body-->
          <div class="py-20">
            <!--begin::Form-->
            <form class="form w-100 fv-plugins-bootstrap5 fv-plugins-framework" novalidate="novalidate"
                  id="kt_sign_in_form" data-kt-redirect-url="#" action="#">
              <!--begin::Body-->
              <div class="card-body">
                <!--begin::Heading-->
                <div class="text-start mb-10">
                  <!--begin::Title-->
                  <h1 class="text-dark mb-3 fs-3x" data-kt-translate="sign-in-title">Sign In</h1>
                  <!--end::Title-->
                  <!--begin::Text-->
                  <!--end::Link-->
                </div>
                <!--begin::Heading-->

                <!--begin::Input group=-->
                <div class="fv-row mb-8 fv-plugins-icon-container">
                  <!--begin::Email-->
                  <input type="text" placeholder="Email" name="email" v-model="email" autocomplete="off"
                         data-kt-translate="sign-in-input-email" class="form-control ">
                  <!--end::Email-->
                  <div class="fv-plugins-message-container invalid-feedback"></div>
                </div>
                <!--end::Input group=-->
                <div class="fv-row mb-7 fv-plugins-icon-container">
                  <!--begin::Password-->
                  <input type="password" placeholder="Password" name="password" v-model="password" autocomplete="off"
                         data-kt-translate="sign-in-input-password" class="form-control ">
                  <!--end::Password-->
                  <div class="fv-plugins-message-container invalid-feedback"></div>
                </div>
                <!--end::Input group=-->
                <!--begin::Wrapper-->
                <div class="d-flex flex-stack flex-wrap gap-3 fs-base fw-semibold mb-10">
                  <div class="text-danger">{{message}}</div>
                </div>
                <!--end::Wrapper-->
                <!--begin::Actions-->
                <div class="d-flex flex-stack">
                  <!--begin::Submit-->
                  <loading-button text="Sign In" :active="isSigningIn" @click.prevent="handleSignIn"/>
                  <!--end::Submit-->


                  <!--begin::Sign Up link-->
                  <!--end::Sign Up link=-->
                </div>
                <!--end::Actions-->
              </div>
              <!--begin::Body-->
            </form>
            <!--end::Form-->
          </div>
          <!--end::Body-->
        </div>
        <!--end::Wrapper-->
      </div>
      <!--end::Aside-->
      <!--begin::Body-->
      <!--begin::Body-->
    </div>
    <!--end::Authentication - Sign-in-->
  </div>
</template>

<script>
  import LoadingButton from "../../components/buttons/LoadingButton.vue";
  import ApiService from "../../core/services/api-service";
  import {APIS} from "../../core/api-urls";

  export default {
  name: "SignIn",
  components: {LoadingButton},
  data(){
    return {
      isSigningIn: false,
      password: "",
      email: "",
      message:""
    }
  },
  methods: {
    async handleSignIn(){
      this.message= "";
      if(!this.email || !this.password){
        this.message = "Please enter an email and password.";
        return;
      }
      try{
        this.isSigningIn = true;
        let response = await ApiService.GeneratePostRequest(APIS.SIGN_IN, {
          Email: this.email,
          Password: this.password
        });
        this.isSigningIn = false;
        if(response && response.status === 200){
          this.$store.commit("Auth/signInSuccessful", {token: response.data.accessToken});
          window.location.reload();
        }else
          this.message = "Username or Password Invalid.";
      }catch (e){
        this.isSigningIn = false;
        this.message = "There was a problem signing you in.";
      }
    }
  }
}
</script>

<style>
#app {
  height: 100vh;
}

#app > div {
  height: 100%;
}
</style>
