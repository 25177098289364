<template>
  <!--begin::Table container-->
  <div class="table-responsive" style="max-height: 450px;    overflow: auto;">
    <!--begin::Table-->
    <table class="table align-middle gs-0 gy-4 border table-row-dashed  table-hover">
      <!--begin::Table head-->
      <thead>
      <tr class="fw-bold text-muted bg-light">
        <th class="ps-4 min-w-125px rounded-start ps-6">Item</th>
        <th class="min-w-125px">Total Paid</th>
      </tr>
      </thead>
      <!--end::Table head-->
      <!--begin::Table body-->
      <tbody>
      <template v-for="(object, property) in data">
        <template v-if="property === 'Items'">
          <template v-for="(item, index) in object.values">
            <tr v-if="item.properties">
              <td v-if="item.properties.Description">
                <input class="form-control text-dark fw-bold  d-block mb-1 fs-6 "
                       @keyup="descriptionChange($event, index)"
                       :value="item.properties.SuggestedDescription || item.properties.Description.value"
                       type="text"/>
              </td>
              <td v-else>
                <input class="form-control text-dark fw-bold  d-block mb-1 fs-6 "
                       @keyup="descriptionChange($event, index)" value="" type="text"/>
              </td>


              <td v-if="item.properties.TotalPrice">
                <div class="input-group mb-1">
                  <span class="input-group-text">$</span>
                  <input class="form-control text-dark fw-bold  d-block fs-6 " @keyup="priceChange($event, index)"
                         :value="item.properties.SuggestedPrice || item.properties.TotalPrice.value" type="number"/>
                </div>
              </td>
              <td v-else>
                <div class="input-group mb-1">
                  <span class="input-group-text">$</span>
                  <input class="form-control text-dark fw-bold  d-block fs-6 " @keyup="priceChange($event,index)"
                         value="0" type="number"/>
                </div>
              </td>
            </tr>
          </template>
        </template>
      </template>
      </tbody>
      <!--end::Table body-->
    </table>
    <!--end::Table-->
  </div>
  <!--end::Table container-->
</template>

<script>
export default {
  name: "import-table",
  props: ["data"],
  emits: ["itemUpdate"],
  methods: {
    priceChange(e, index) {
      let val = e.target.value.trim();
      if (val !== "")
        this.$emit("itemUpdate", "SuggestedPrice", val, index);
    },
    descriptionChange(e, index) {
      let val = e.target.value.trim();
      if (val !== "")
        this.$emit("itemUpdate", "SuggestedDescription", val, index);
    },
  }
}
</script>

<style scoped>

</style>
