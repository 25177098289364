<template>
  <h2>Onboard Company</h2>

  <div class="card mb-6">
    <div class="card-body pt-9 pb-9">
      <!--begin::Stepper-->
      <!--begin::Stepper-->
      <div class="stepper stepper-pills stepper-column d-flex flex-column flex-lg-row" id="kt_stepper_example_vertical">
        <!--begin::Aside-->
        <div class="d-flex flex-row-auto w-100 w-lg-300px">
          <!--begin::Nav-->
          <div class="stepper-nav flex-cente">
            <!--begin::Step 1-->
            <div class="stepper-item me-5 current" data-kt-stepper-element="nav">
              <!--begin::Wrapper-->
              <div class="stepper-wrapper d-flex align-items-center">
                <!--begin::Icon-->
                <div class="stepper-icon w-40px h-40px">
                  <i class="stepper-check fas fa-check"></i>
                  <span class="stepper-number">1</span>
                </div>
                <!--end::Icon-->

                <!--begin::Label-->
                <div class="stepper-label">
                  <h3 class="stepper-title">
                    Step 1
                  </h3>

                  <div class="stepper-desc">
                    Select or Create a Company
                  </div>
                </div>
                <!--end::Label-->
              </div>
              <!--end::Wrapper-->

              <!--begin::Line-->
              <div class="stepper-line h-40px"></div>
              <!--end::Line-->
            </div>
            <!--end::Step 1-->

            <!--begin::Step 2-->
            <div class="stepper-item me-5" data-kt-stepper-element="nav">
              <!--begin::Wrapper-->
              <div class="stepper-wrapper d-flex align-items-center">
                <!--begin::Icon-->
                <div class="stepper-icon w-40px h-40px">
                  <i class="stepper-check fas fa-check"></i>
                  <span class="stepper-number">2</span>
                </div>
                <!--begin::Icon-->

                <!--begin::Label-->
                <div class="stepper-label">
                  <h3 class="stepper-title">
                    Step 2
                  </h3>

                  <div class="stepper-desc">
                    Choose Document Type
                  </div>
                </div>
                <!--end::Label-->
              </div>
              <!--end::Wrapper-->

              <!--begin::Line-->
              <div class="stepper-line h-40px"></div>
              <!--end::Line-->
            </div>
            <!--end::Step 2-->

            <!--begin::Step 3-->
            <div class="stepper-item me-5" data-kt-stepper-element="nav">
              <!--begin::Wrapper-->
              <div class="stepper-wrapper d-flex align-items-center">
                <!--begin::Icon-->
                <div class="stepper-icon w-40px h-40px">
                  <i class="stepper-check fas fa-check"></i>
                  <span class="stepper-number">3</span>
                </div>
                <!--begin::Icon-->

                <!--begin::Label-->
                <div class="stepper-label">
                  <h3 class="stepper-title">
                    Step 3
                  </h3>

                  <div class="stepper-desc">
                    Operation Description
                  </div>
                </div>
                <!--end::Label-->
              </div>
              <!--end::Wrapper-->

              <!--begin::Line-->
              <div class="stepper-line h-40px"></div>
              <!--end::Line-->
            </div>
            <!--end::Step 3-->
            <!--begin::Step 4-->
            <div class="stepper-item me-5" data-kt-stepper-element="nav">
              <!--begin::Wrapper-->
              <div class="stepper-wrapper d-flex align-items-center">
                <!--begin::Icon-->
                <div class="stepper-icon w-40px h-40px">
                  <i class="stepper-check fas fa-check"></i>
                  <span class="stepper-number">4</span>
                </div>
                <!--begin::Icon-->

                <!--begin::Label-->
                <div class="stepper-label">
                  <h3 class="stepper-title">
                    Step 4
                  </h3>

                  <div class="stepper-desc">
                    Upload Documents
                  </div>
                </div>
                <!--end::Label-->
              </div>
              <!--end::Wrapper-->

              <!--begin::Line-->
              <div class="stepper-line h-40px"></div>
              <!--end::Line-->
            </div>
            <!--end::Step 4-->

          </div>
          <!--end::Nav-->
        </div>

        <!--begin::Content-->
        <div class="flex-row-fluid">
          <!--begin::Form-->
          <form class="form " novalidate="novalidate">
            <!--begin::Group-->
            <div class="mb-5">
              <!--begin::Step 1-->
              <div class="flex-column current" data-kt-stepper-element="content">
                <!--begin::Input group-->
                <div class="fv-row mb-10">
                  <!--begin::Label-->
                  <label class="form-label">Select or Enter a New Company</label>
                  <!--end::Label-->

                  <!--begin::Input-->
                  <select class="form-select" data-placeholder="Select a Company" id="company_dropdown">
                    <option></option>
                  </select>
                  <!--end::Input-->
                  <p class="text-danger">{{stepperErrorMsg}}</p>
                </div>
              </div>
              <!--begin::Step 1-->

              <!--begin::Step 1-->
              <div class="flex-column" data-kt-stepper-element="content">
                <!--begin::Input group-->
                <div class="fv-row mb-10 d-flex flex-row" style="gap: 20px">
                  <outlined-button-card v-for="scanType in scanTypes" :value="scanType.Code" :summary="scanType.Description" :title="scanType.Title" :checked="selectedDocType === scanType.Code" @click.prevent="docTypeChanged(scanType)"/>
                </div>

                <div class="fv-row mb-10 d-flex flex-row" style="gap: 20px">
                  <p class="text-danger">{{stepperErrorMsg}}</p>
                </div>
                <!--end::Input group-->
              </div>
              <!--begin::Step 1-->
              <!--begin::Step 1-->
              <div class="flex-column" data-kt-stepper-element="content">
                <!--begin::Input group-->
                <div class="fv-row mb-10">
                  <!--begin::Label-->
                  <label class="form-label">Operation Description</label>
                  <!--end::Label-->

                  <!--begin::Input-->
                  <textarea rows="10" class="form-control" v-model="descriptionModel" placeholder="Describe the sequence of actions and steps needed to do this operation."></textarea>
                  <!--end::Input-->
                  <p class="text-danger">{{stepperErrorMsg}}</p>
                </div>
              </div>
              <!--begin::Step 1-->

              <!--begin::Step 1-->
              <div class="flex-column" data-kt-stepper-element="content">
                <!--begin::Form-->
                <form class="form" action="#" method="post">
                  <!--begin::Input group-->
                  <div class="fv-row">
                    <!--begin::Dropzone-->
                    <div class="dropzone min-h-400px d-flex flex-center flex-wrap" id="kt_dropzonejs_example_1">
                      <!--begin::Message-->
                      <div class="dz-message needsclick">
                        <i class="ki-duotone ki-file-up fs-3x text-primary"><span class="path1"></span><span class="path2"></span></i>

                        <!--begin::Info-->
                        <div class="ms-4">
                          <h3 class="fs-5 fw-bold text-gray-900 mb-1">Drop files here or click to upload.</h3>
                          <span class="fs-7 fw-semibold text-gray-500">Upload up to 10 files</span>
                          <p class="text-danger">{{stepperErrorMsg}}</p>
                        </div>
                        <!--end::Info-->
                      </div>
                    </div>
                    <!--end::Dropzone-->
                  </div>
                  <!--end::Input group-->
                </form>
                <!--end::Form-->
              </div>
              <!--begin::Step 1-->

            </div>
            <!--end::Group-->

            <!--begin::Actions-->
            <div class="d-flex flex-stack">
              <!--begin::Wrapper-->
              <div class="me-2">
                <button type="button" class="btn btn-light btn-active-light-primary" data-kt-stepper-action="previous" @click.prevent="back">
                  Back
                </button>
              </div>
              <!--end::Wrapper-->

              <!--begin::Wrapper-->
              <div>
                <button type="button" :disabled="isUploading" class="btn btn-primary" data-kt-stepper-action="submit" @click.prevent="submit">
                        <span class="indicator-label">
                            Submit
                        </span>
                  <span class="indicator-progress">
                            Please wait... <span class="spinner-border spinner-border-sm align-middle ms-2"></span>
                        </span>
                </button>

                <button type="button" class="btn btn-primary" data-kt-stepper-action="next" @click.prevent="next">
                  Continue
                </button>
              </div>
              <!--end::Wrapper-->
            </div>
            <!--end::Actions-->
          </form>
          <!--end::Form-->
        </div>
      </div>
      <!--end::Stepper-->
      <!--end::Stepper-->
    </div>
  </div>
</template>

<script>
  import LoadingButton from '../../components/buttons/LoadingButton.vue'
  import DocumentThumbnail from '../../components/documents/document-thumbnail.vue'
  import ImportOverview from '../../components/finances/import-overview.vue'
  import UploadScans from '../../components/finances/upload-scans.vue'
  import ImportScans from '../../components/finances/import-scans.vue'
  import OutlinedButtonCard from '@/components/OutlinedButtonCard.vue'
  import ApiService from '@/core/services/api-service'
  import { APIS } from '@/core/api-urls'

  export default {
    name: 'OnboardCompany',
    components: { OutlinedButtonCard, ImportScans, UploadScans, ImportOverview, DocumentThumbnail, LoadingButton },
    data () {
      return {
        stepper: null,
        companyDropdown: null,
        dropzone: null,


        scanTypes: [],
        companies: [],
        companyActiveScanTypes: [],


        descriptionModel: "",
        selectedCompany: "",
        selectedDocType: "",
        stepperErrorMsg: "",


        isUploading: false,
      }

    },
    async created () {
      this.scanTypes = await ApiService.GenerateGetRequest(APIS.GET_SCAN_TYPES);
    },
    watch:{
      selectedDocType(newValue, oldValue) {
        this.descriptionModel = "";
        const selected = this.companyActiveScanTypes.find((scanType) => scanType.ScanTypeCode === newValue);
        if(selected){
          this.descriptionModel = selected.Description;
        }
      }
    },
    async mounted () {
      this.companies = await ApiService.GenerateGetRequest(APIS.GET_COMPANIES);
      this.stepper = new KTStepper( document.querySelector('#kt_stepper_example_vertical'));

      this.companyDropdown = $('#company_dropdown').select2({data: this.companies.map(item => {return {id: item.Id, text: item.Name}}), tags: true});
      this.companyDropdown.on('change', async (e) =>{
        const val = $('#company_dropdown').val();
        if(!isNaN(val)){
          this.companyActiveScanTypes = await ApiService.GenerateGetRequest(APIS.GET_COMPANY_ACTIVE_SCAN_TYPES + val);
          console.log(this.companyActiveScanTypes);
        }
      });
      this.dropzone = new Dropzone("#kt_dropzonejs_example_1", {
        url: "https://keenthemes.com/scripts/void.php", // Set the url for your upload script location
        paramName: "file", // The name that will be used to transfer the file
        maxFiles: 15,
        maxFilesize: 10, // MB
        addRemoveLinks: true,
        autoProcessQueue: false,
        accept: function(file, done) {
          if (file.name == "wow.jpg") {
            done("Naha, you don't.");
          } else {
            done();
          }
        }
      });
    },
    methods: {
      docTypeChanged(scanType){
        this.selectedDocType = scanType.Code;
      },
      next () {
        this.stepperErrorMsg = "";
        switch (this.stepper.getCurrentStepIndex()){
          case 1:
            this.selectedCompany =$('#company_dropdown').val();
            if(this.selectedCompany === ""){
              this.stepperErrorMsg = "Select or Enter a Company.";
              return;
            }
            break;
          case 2:
            if(this.selectedDocType === ""){
              this.stepperErrorMsg = "Select a Document Type.";
              return;
            }
            break;
          case 3:
            if(this.descriptionModel === ""){
              this.stepperErrorMsg = "Enter operation description.";
              return;
            }
        }
        this.stepper.goNext();
      },
      back () {
        this.stepper.goPrevious();
      },
      async uploadFiles  (files, metadata) {
        const formData = new FormData();

        // Append each file to the form data
        Array.from(files).forEach((file) => {
          formData.append('files', file);
        });

        // Append the metadata as a JSON string
        formData.append('ai_training_documents', JSON.stringify(metadata));

        // Make the POST request to the upload endpoint
        const response = await ApiService.GeneratePostFormDataRequest(APIS.ADD_ONBOARDING, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
            Authorization: `Bearer YOUR_ACCESS_TOKEN`, // Include the token if needed
          },
        });
        Swal.fire({
          text: 'Training Documents Uploaded.',
          icon: 'success',
          buttonsStyling: false,
          confirmButtonText: 'Close!',
          customClass: {
            confirmButton: 'btn btn-primary'
          }
        })
        this.isUploading = false;

        this.dropzone.removeAllFiles();
      },

      submit(){
        if(this.isUploading){
          return;
        }
        this.isUploading = true;
        const company =  $('#company_dropdown').val();
        const selectedType = this.selectedDocType;
        const description = this.descriptionModel;
        const files =this.dropzone.files;
        if(files.length <= 0){
          this.stepperErrorMsg = "Add at least one document.";
          return;
        }

        const metadata = {
          CompanyId: !isNaN(company)  ? parseInt(company) : company,
          ScanTypeCode: selectedType,
          Description: description
        };

        this.uploadFiles(files, metadata);
      }
    }
  }
</script>

