<template>
  <div class="card mb-6">
    <div class="card-body pt-9 pb-9">
      <ul class="nav nav-tabs nav-line-tabs nav-line-tabs-2x mb-5 fs-6">
        <li class="nav-item">
          <a class="nav-link active  p-3" data-bs-toggle="tab" href="#kt_tab_pane_4">Upload Scans</a>
        </li>
        <li class="nav-item">
          <a class="nav-link position-relative p-3" data-bs-toggle="tab" href="#kt_tab_pane_5">Import Scans
            <span v-if="scanCount > 0"
                  class="badge-sm badge badge-circle badge-success position-absolute top-0 start-100 translate-middle-x">{{ scanCount }}</span>
          </a>
        </li>
      </ul>

      <div class="tab-content" id="myTabContent">
        <div class="tab-pane fade show active" id="kt_tab_pane_4" role="tabpanel">
          <upload-scans/>
        </div>
        <div class="tab-pane fade" id="kt_tab_pane_5" role="tabpanel">
          <import-scans @scanCount="onScanCountChange"/>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import LoadingButton from "../../components/buttons/LoadingButton.vue";
import DocumentThumbnail from "../../components/documents/document-thumbnail.vue";
import ImportOverview from "../../components/finances/import-overview.vue";
import UploadScans from "../../components/finances/upload-scans.vue";
import ImportScans from "../../components/finances/import-scans.vue";

export default {
  name: "AnalyseDocument",
  components: {ImportScans, UploadScans, ImportOverview, DocumentThumbnail, LoadingButton},
  data() {
    return {
      scanCount: 0,
    }

  },
  async created() {

  },
  mounted() {

  },
  methods: {
    onScanCountChange(value) {
      this.scanCount = value;
    }
  }
}
</script>

