<template>
  <a-p-i-table title="MyTable" api="id" param="id" :fields="fields" :fetch-url="'GET_COMPANIES'" :delete-url="'DELETE_COMPANIES'" :columns="columns"/>
</template>

<script>
  import ApiService from '@/core/services/api-service'
  import { APIS } from '@/core/api-urls'
  import Datatable from '@/components/tables/datatable.vue'
  import LoadingButton from '@/components/buttons/LoadingButton.vue'
  import APITable from '@/components/tables/APITable.vue'

  export default {
    name: 'Test',
    components: { APITable, LoadingButton, Datatable,  },
    data () {
      return {
        data: [],
        systemRoles:[],


        fields: [{
          label: 'Name',
          type: 'input',
          element: 'input',
          placeholder: 'Company A',
          api: "name",
          param: 'name',
          validate: function (value){
            return [value.length > 0, "Name is required."];
          }
        },{
          label: 'Phone',
          type: 'number',
          element: 'input',
          placeholder: '1234567',
          api: "phone",
          param: 'phone',

        },{
          label: 'Address',
          type: 'text',
          element: 'input',
          placeholder: '',
          api: "address",
          param: 'address',
        }],
        columns: [
          {
            title: 'Name',
            data: 'name',
            config: {
              className:'d-flex align-items-center',
              targets: 0,
              orderable: true,
              render: function (data, type, object) {
                return data;
              }
            }
          }, {
            title: 'Phone',
            data: 'phone',
            config: {
              targets: 1,
              orderable: true,
              render: function (data, type, object) {
                return data;
              }
            }
          }, {
            title: 'Address',
            data: 'address',
            config: {
              targets:2,
              orderable: true,
              render: function (data, type, object) {
                return data;
              }
            }
          },
        ],




        nameVM: "",
        phoneVM: "",
        addressVM: "",
        saveErrorMsg:"",
        selectedRole: null,
        isSaving: false,
      }

    },
    async created () {
      this.data = await ApiService.GenerateGetRequest(APIS.GET_COMPANIES);
    },
    mounted () {

    },
    updated () {

    },
    methods: {
      async save () {
        this.isSaving = true;

        this.saveErrorMsg = "";
        const payload = {
          name: this.nameVM,
          address: this.addressVM,
          phone: this.phoneVM,
        }

        try {
          let response = await ApiService.GeneratePostRequest(APIS.ADD_COMPANY, payload);
          this.nameVM = "";
          this.addressVM = "";
          this.phoneVM = "";
          Swal.fire({
            text: "Company Created!",
            icon: "success",
            buttonsStyling: false,
            confirmButtonText: "Close!",
            customClass: {
              confirmButton: "btn btn-primary"
            }
          });
          this.isSaving = false;

          this.$refs.dataTable.reload();
        }catch (error) {
          this.saveErrorMsg = error.message;
        }

        this.isSaving = false;
      },
      async handleRowInteraction(e, action, data){
        if(action === "delete"){
          try {
            let response = await ApiService.GenerateDeleteRequest(APIS.DELETE_COMPANIES + data.id);
            Swal.fire({
              text: "Company Deleted!",
              icon: "success",
              buttonsStyling: false,
              confirmButtonText: "Close!",
              customClass: {
                confirmButton: "btn btn-primary"
              }
            });
            this.$refs.dataTable.reload();
          }catch (error) {
            Swal.fire({
              text: "Unable to delete company! " + error.message,
              icon: "error",
              buttonsStyling: false,
              confirmButtonText: "Close!",
              customClass: {
                confirmButton: "btn btn-primary"
              }
            });
          }
        }


      }
    }
  }
</script>

