

<style scoped>

</style>
<template>
  <div class="flex-fill mb-5 min-w-475px">
    <input type="radio" class="btn-check" :checked="checked">
    <label
        class="btn btn-outline btn-outline-dashed  btn-active-light-primary btn-outline-primary p-7 d-flex align-items-center"
        for="invoice">

      <!--begin::Svg Icon | path: /var/www/preview.keenthemes.com/kt-products/docs/metronic/html/releases/2022-12-26-231111/core/html/src/media/icons/duotune/ecommerce/ecm004.svg-->
      <span class="svg-icon svg-icon-3x me-5"><svg width="24" height="24" viewBox="0 0 24 24"
                                                   fill="none"
                                                   xmlns="http://www.w3.org/2000/svg">
<path opacity="0.3" d="M18 10V20C18 20.6 18.4 21 19 21C19.6 21 20 20.6 20 20V10H18Z" fill="currentColor"/>
<path opacity="0.3" d="M11 10V17H6V10H4V20C4 20.6 4.4 21 5 21H12C12.6 21 13 20.6 13 20V10H11Z" fill="currentColor"/>
<path opacity="0.3" d="M10 10C10 11.1 9.1 12 8 12C6.9 12 6 11.1 6 10H10Z" fill="currentColor"/>
<path opacity="0.3" d="M18 10C18 11.1 17.1 12 16 12C14.9 12 14 11.1 14 10H18Z" fill="currentColor"/>
<path opacity="0.3" d="M14 4H10V10H14V4Z" fill="currentColor"/>
<path opacity="0.3" d="M17 4H20L22 10H18L17 4Z" fill="currentColor"/>
<path opacity="0.3" d="M7 4H4L2 10H6L7 4Z" fill="currentColor"/>
<path
    d="M6 10C6 11.1 5.1 12 4 12C2.9 12 2 11.1 2 10H6ZM10 10C10 11.1 10.9 12 12 12C13.1 12 14 11.1 14 10H10ZM18 10C18 11.1 18.9 12 20 12C21.1 12 22 11.1 22 10H18ZM19 2H5C4.4 2 4 2.4 4 3V4H20V3C20 2.4 19.6 2 19 2ZM12 17C12 16.4 11.6 16 11 16H6C5.4 16 5 16.4 5 17C5 17.6 5.4 18 6 18H11C11.6 18 12 17.6 12 17Z"
    fill="currentColor"/>
</svg>
</span>
      <!--end::Svg Icon-->
      <!--begin::Info-->
      <span class="d-block fw-semibold text-start">
															<span class="text-dark fw-bold d-block fs-4 mb-2">{{ title }}</span>
															<span class="text-muted fw-semibold fs-6">{{summary}}</span>
														</span>
      <!--end::Info-->
    </label>
  </div>
</template>
<script>
  export default {
    name: 'OutlinedButtonCard',
    data(){
      return {model: ""}
    },
    props: ["name", "value", "title", "summary", "checked"],
  }
</script>