<template>
  <div class="card mb-5 mb-xl-8">
    <!--begin::Header-->
    <div class="card-header border-0 ">
      <h3 class="card-title align-items-start flex-column w-100">
        <span class="card-label  fs-4 mb-1">Merchant Name</span>
        <span class="text-muted mt-1 fw-semibold fs-7 w-100">

          <input class="form-control"
                 @keyup="merchantNameChange($event)"
                 :value="headings.MerchantName"/></span>
      </h3>
      <h3 class="card-title align-items-start flex-column">
        <span class="card-label  fs-4 mb-1">Transaction Date</span>
        <span class="text-muted mt-1 fw-semibold fs-7"><input class="form-control"
                                                              @keyup="transactionDateChange($event)"
                                                              :value="headings.TransactionDate"/></span>
      </h3>

      <h3 class="card-title align-items-start flex-column">
        <span class="card-label  fs-4 mb-1">Total</span>
        <span class="text-muted mt-1 fw-semibold fs-7">
          <div class="input-group mb-1">
                 <span class="input-group-text">$</span>
            <input class="form-control"
                   @keyup="totalChange($event)"
                   type="number"
                   :value="headings.MerchantTotal"/>
               </div>
          </span>
      </h3>
    </div>
    <!--end::Header-->
    <!--begin::Body-->
    <div class="card-body py-3">
      <import-table :data="content" @itemUpdate="itemUpdated"/>
    </div>
    <!--begin::Body-->
  </div>
</template>

<script>
import Select2 from "../../components/inputs/select2.vue";
import ImportTable from "./import-table.vue";
// TODO: Input Verification
export default {
  name: "import-overview",
  components: {ImportTable,},
  props: ["content"],
  emits: ["contentUpdate"],
  data() {
    return {
      headings: {
        MerchantName: "",
        MerchantTotal: "",
        TransactionDate: "",
        Items: []
      }
    }
  },
  created() {
    this.processData(this.content);
  },
  watch: {
    content(newVal, oldVal) {
      this.processData(newVal);
    }
  },
  methods: {
    processData(data) {
      this.headings.MerchantName = data["MerchantName"] ? data["MerchantName"].value : "";
      this.headings.MerchantTotal = data["Total"] ? data["Total"].value : "";
      this.headings.TransactionDate = data["TransactionDate"] ? data["TransactionDate"].value : "";
      if (this.headings.TransactionDate) {
        this.headings.TransactionDate = moment(this.headings.TransactionDate).format("DD-MM-YYYY")
      }
      this.headings.Items = data["Items"] ? data["Items"].value : [];
    },


    merchantNameChange(event) {
      if (!this.content.MerchantName) {
        this.content.MerchantName = {
          SuggestedMerchantName: event.target.value,
          value: event.target.value
        };
      } else {
        this.content.MerchantName.SuggestedMerchantName = event.target.value;
      }
      this.$emit("contentUpdate", JSON.stringify(this.content));
    },
    transactionDateChange(event) {

      if (!this.content.TransactionDate) {
        this.content.TransactionDate = {
          SuggestedTransactionDate: event.target.value,
          value: event.target.value
        };
      } else {
        this.content.MerchantName.SuggestedTransactionDate = event.target.value;
      }
      this.$emit("contentUpdate", JSON.stringify(this.content));
    },
    totalChange(event) {
      if (!this.content.Total) {
        this.content.Total = {
          SuggestedTotal: event.target.value,
          value: event.target.value
        };
      } else {
        this.content.Total.SuggestedTotal = event.target.value;
      }
      console.log(event.target.value)
      this.$emit("contentUpdate", JSON.stringify(this.content));
    },
    itemUpdated(field, value, index) {
      if (this.content.Items && this.content.Items.values && this.content.Items.values.length > 0) {
        this.content.Items.values[index].properties[field] = value;
        this.$emit("contentUpdate", JSON.stringify(this.content));
      }
    }
  }

}
</script>

<style scoped>

</style>
