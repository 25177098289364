export const APIS = {
  SIGN_IN: '/auth/login',
  SIGN_UP: '/customer',
  ANALYSE_DOCUMENTS_URL: '/scan/upload',
  READY_IMPORT_DOCUMENTS_URL: '/scan/ready',
  IMPORT_DOCUMENT_URL: '/scan/import',
  LOOK_UP_CATEGORIES: '/lookup/categories',
  LOOK_UP_CUSTOMER_MERCHANTS: '/lookup/merchants',
  GET_CUSTOMER_SCAN_LIST: '/scan',
  GET_CUSTOMER_PRODUCT_LIST: '/product',
  GET_CUSTOMER_SCAN_ITEMS: '/scan/items',
  UPDATE_CUSTOMER_SCAN: '/scan/update',
  
  ADD_EMPLOYEE: '/employee/create',
  UPDATE_EMPLOYEE: '/employee/update',
  DELETE_EMPLOYEE: '/employee/delete/',
  GET_EMPLOYEES: '/employee',
  
  ADD_COMPANY: '/companies/create',
  UPDATE_COMPANY: '/companies/update',
  GET_COMPANIES: '/companies',
  DELETE_COMPANIES: '/companies/delete/',
  
  
  GET_SCAN_TYPES: '/scan_type',
  
  GET_SYSTEM_ROLES: '/roles',
  ADD_ROLES: '/roles/create',
  UPDATE_ROLES: '/roles/update',
  DELETE_ROLES: '/roles/delete/',
  
  GET_COMPANY_ACTIVE_SCAN_TYPES: '/active_company_scan_types/',
  
  ADD_ROLE_PERMISSIONS: '/role_permissions/create',
  ADD_ROLE_PERMISSIONS_BULK: '/role_permissions/create/bulk',
  
  GET_PERMISSIONS: '/permissions',
  
  
  ADD_ONBOARDING: '/ai_training_documents/upload',
  
  
  PASSWORD_RESET_REQUEST: '/password_rest_tokens/create',
  PASSWORD_USE_RESET_TOKEN: '/password_rest_tokens/use-token'
}
