<template>

  <!--begin::Stepper-->
  <div class="stepper stepper-pills stepper-column d-flex flex-column flex-lg-row"
       ref="kt_stepper_example_vertical">
    <!--begin::Aside-->
    <div class="d-flex flex-row-auto w-100 w-lg-300px">
      <!--begin::Nav-->
      <div class="stepper-nav flex-cente">
        <!--begin::Step 1-->
        <div class="stepper-item me-5 current" data-kt-stepper-element="nav">
          <!--begin::Wrapper-->
          <div class="stepper-wrapper d-flex align-items-center">
            <!--begin::Icon-->
            <div class="stepper-icon w-40px h-40px">
              <i class="stepper-check fas fa-check"></i>
              <span class="stepper-number">1</span>
            </div>
            <!--end::Icon-->

            <!--begin::Label-->
            <div class="stepper-label">
              <h3 class="stepper-title">
                Import Type
              </h3>

              <div class="stepper-desc">
                Choose the type of import you would like to do
              </div>
            </div>
            <!--end::Label-->
          </div>
          <!--end::Wrapper-->

          <!--begin::Line-->
          <div class="stepper-line h-40px"></div>
          <!--end::Line-->
        </div>
        <!--end::Step 1-->

        <!--begin::Step 2-->
        <div class="stepper-item me-5" data-kt-stepper-element="nav">
          <!--begin::Wrapper-->
          <div class="stepper-wrapper d-flex align-items-center">
            <!--begin::Icon-->
            <div class="stepper-icon w-40px h-40px">
              <i class="stepper-check fas fa-check"></i>
              <span class="stepper-number">2</span>
            </div>
            <!--begin::Icon-->

            <!--begin::Label-->
            <div class="stepper-label">
              <h3 class="stepper-title">
                Upload Scans
              </h3>

              <div class="stepper-desc">
                Scanned copies of your physical documents
              </div>
            </div>
            <!--end::Label-->
          </div>
          <!--end::Wrapper-->

          <!--begin::Line-->
          <div class="stepper-line h-40px"></div>
          <!--end::Line-->
        </div>
        <!--end::Step 2-->

      </div>
      <!--end::Nav-->
    </div>

    <!--begin::Content-->
    <div class="flex-row-fluid">
      <!--begin::Form-->
      <form class="form  mx-auto" novalidate="novalidate">
        <!--begin::Group-->
        <div class="mb-5">
          <!--begin::Step 1-->
          <div class="flex-column current justify-content-evenly " data-kt-stepper-element="content">
            <div class="flex-fill mb-5">
              <!--begin::Option-->
              <input type="radio" class="btn-check" name="analyse_type" v-model="analyseType" value="RCPT"
                     id="receipt">
              <label
                  class="btn btn-outline btn-outline-dashed btn-active-light-primary btn-outline-primary p-7 d-flex align-items-center"
                  for="receipt">
                <!--begin::Svg Icon | path: /var/www/preview.keenthemes.com/kt-products/docs/metronic/html/releases/2022-12-26-231111/core/html/src/media/icons/duotune/text/txt010.svg-->
                <span class="svg-icon svg-icon-3x me-5"><svg width="24" height="24" viewBox="0 0 24 24"
                                                             fill="none"
                                                             xmlns="http://www.w3.org/2000/svg">
<path opacity="0.3"
      d="M17.1 10.5H11.1C10.5 10.5 10.1 10.1 10.1 9.5V8.5C10.1 7.9 10.5 7.5 11.1 7.5H17.1C17.7 7.5 18.1 7.9 18.1 8.5V9.5C18.1 10.1 17.7 10.5 17.1 10.5ZM22.1 4.5V3.5C22.1 2.9 21.7 2.5 21.1 2.5H11.1C10.5 2.5 10.1 2.9 10.1 3.5V4.5C10.1 5.1 10.5 5.5 11.1 5.5H21.1C21.7 5.5 22.1 5.1 22.1 4.5ZM22.1 15.5V14.5C22.1 13.9 21.7 13.5 21.1 13.5H11.1C10.5 13.5 10.1 13.9 10.1 14.5V15.5C10.1 16.1 10.5 16.5 11.1 16.5H21.1C21.7 16.5 22.1 16.1 22.1 15.5ZM18.1 20.5V19.5C18.1 18.9 17.7 18.5 17.1 18.5H11.1C10.5 18.5 10.1 18.9 10.1 19.5V20.5C10.1 21.1 10.5 21.5 11.1 21.5H17.1C17.7 21.5 18.1 21.1 18.1 20.5Z"
      fill="currentColor"/>
<path
    d="M5.60001 10.5C5.30001 10.5 5.00002 10.4 4.80002 10.2C4.60002 9.99995 4.5 9.70005 4.5 9.30005V5.40002C3.7 5.90002 3.40001 6 3.10001 6C2.90001 6 2.6 5.89995 2.5 5.69995C2.3 5.49995 2.20001 5.3 2.20001 5C2.20001 4.6 2.4 4.40005 2.5 4.30005C2.6 4.20005 2.80001 4.10002 3.10001 3.90002C3.50001 3.70002 3.8 3.50005 4 3.30005C4.2 3.10005 4.40001 2.89995 4.60001 2.69995C4.80001 2.39995 4.9 2.19995 5 2.19995C5.1 2.09995 5.30001 2 5.60001 2C5.90001 2 6.10002 2.10002 6.30002 2.40002C6.50002 2.60002 6.5 2.89995 6.5 3.19995V9C6.6 10.4 5.90001 10.5 5.60001 10.5ZM7.10001 21.5C7.40001 21.5 7.69999 21.4 7.89999 21.2C8.09999 21 8.20001 20.8 8.20001 20.5C8.20001 20.2 8.10002 19.9 7.80002 19.8C7.60002 19.6 7.3 19.6 7 19.6H5.10001C5.30001 19.4 5.50002 19.2 5.80002 19C6.30002 18.6 6.69999 18.3 6.89999 18.1C7.09999 17.9 7.40001 17.6 7.60001 17.2C7.80001 16.8 8 16.3 8 15.9C8 15.6 7.90002 15.3 7.80002 15C7.70002 14.7 7.50002 14.5 7.30002 14.2C7.10002 14 6.80001 13.8 6.60001 13.7C6.20001 13.5 5.70001 13.4 5.10001 13.4C4.60001 13.4 4.20002 13.5 3.80002 13.6C3.40002 13.7 3.09999 13.9 2.89999 14.2C2.69999 14.4 2.50002 14.7 2.30002 15C2.20002 15.3 2.10001 15.6 2.10001 15.9C2.10001 16.3 2.29999 16.5 2.39999 16.6C2.59999 16.8 2.80001 16.9 3.10001 16.9C3.50001 16.9 3.70002 16.7 3.80002 16.6C3.90002 16.4 4.00001 16.2 4.10001 16C4.20001 15.8 4.20001 15.7 4.20001 15.7C4.40001 15.4 4.59999 15.3 4.89999 15.3C4.99999 15.3 5.20002 15.3 5.30002 15.4C5.40002 15.5 5.50001 15.5 5.60001 15.7C5.70001 15.8 5.70001 15.9 5.70001 16.1C5.70001 16.2 5.70001 16.4 5.60001 16.6C5.50001 16.8 5.40001 16.9 5.20001 17.1C5.00001 17.3 4.80001 17.5 4.60001 17.6C4.40001 17.7 4.20002 17.9 3.80002 18.3C3.40002 18.6 3.00001 19 2.60001 19.5C2.50001 19.6 2.30001 19.8 2.20001 20.1C2.10001 20.4 2 20.6 2 20.7C2 21 2.10002 21.3 2.30002 21.5C2.50002 21.7 2.80001 21.8 3.20001 21.8H7.10001V21.5Z"
    fill="currentColor"/>
</svg>
</span>
                <!--end::Svg Icon-->
                <!--begin::Info-->
                <span class="d-block fw-semibold text-start">
															<span class="text-dark fw-bold d-block fs-4 mb-2">Receipt or Linx Receipt</span>
															<span class="text-muted fw-semibold fs-6">A receipt which usually contains the items purchased and totals</span>
														</span>
                <!--end::Info-->
              </label>
              <!--end::Option-->
            </div>
            <div class="flex-fill mb-5">
              <!--begin::Option-->
              <input type="radio" class="btn-check" name="analyse_type" v-model="analyseType" value="INVC"
                     id="invoice">
              <label
                  class="btn btn-outline btn-outline-dashed  btn-active-light-primary btn-outline-primary p-7 d-flex align-items-center"
                  for="invoice">

                <!--begin::Svg Icon | path: /var/www/preview.keenthemes.com/kt-products/docs/metronic/html/releases/2022-12-26-231111/core/html/src/media/icons/duotune/ecommerce/ecm004.svg-->
                <span class="svg-icon svg-icon-3x me-5"><svg width="24" height="24" viewBox="0 0 24 24"
                                                             fill="none"
                                                             xmlns="http://www.w3.org/2000/svg">
<path opacity="0.3" d="M18 10V20C18 20.6 18.4 21 19 21C19.6 21 20 20.6 20 20V10H18Z" fill="currentColor"/>
<path opacity="0.3" d="M11 10V17H6V10H4V20C4 20.6 4.4 21 5 21H12C12.6 21 13 20.6 13 20V10H11Z" fill="currentColor"/>
<path opacity="0.3" d="M10 10C10 11.1 9.1 12 8 12C6.9 12 6 11.1 6 10H10Z" fill="currentColor"/>
<path opacity="0.3" d="M18 10C18 11.1 17.1 12 16 12C14.9 12 14 11.1 14 10H18Z" fill="currentColor"/>
<path opacity="0.3" d="M14 4H10V10H14V4Z" fill="currentColor"/>
<path opacity="0.3" d="M17 4H20L22 10H18L17 4Z" fill="currentColor"/>
<path opacity="0.3" d="M7 4H4L2 10H6L7 4Z" fill="currentColor"/>
<path
    d="M6 10C6 11.1 5.1 12 4 12C2.9 12 2 11.1 2 10H6ZM10 10C10 11.1 10.9 12 12 12C13.1 12 14 11.1 14 10H10ZM18 10C18 11.1 18.9 12 20 12C21.1 12 22 11.1 22 10H18ZM19 2H5C4.4 2 4 2.4 4 3V4H20V3C20 2.4 19.6 2 19 2ZM12 17C12 16.4 11.6 16 11 16H6C5.4 16 5 16.4 5 17C5 17.6 5.4 18 6 18H11C11.6 18 12 17.6 12 17Z"
    fill="currentColor"/>
</svg>
</span>
                <!--end::Svg Icon-->
                <!--begin::Info-->
                <span class="d-block fw-semibold text-start">
															<span class="text-dark fw-bold d-block fs-4 mb-2">Invoice</span>
															<span class="text-muted fw-semibold fs-6">Usually from a company that consists of goods and services</span>
														</span>
                <!--end::Info-->
              </label>
              <!--end::Option-->
            </div>
          </div>
          <!--begin::Step 1-->

          <!--begin::Step 2-->
          <div class="flex-column min-h-450px" data-kt-stepper-element="content">
            <div v-if="totalUploads > 0 && totalUploads !== totalUploaded"
                 class="alert alert-secondary d-flex align-items-center p-5 mb-10">
              <!--begin::Svg Icon | path: icons/duotune/general/gen048.svg-->
              <span class="svg-icon svg-icon-2hx  me-4">
														<svg width="24" height="24" viewBox="0 0 24 24" fill="none"
                                 xmlns="http://www.w3.org/2000/svg">
															<path opacity="0.3"
                                    d="M20.5543 4.37824L12.1798 2.02473C12.0626 1.99176 11.9376 1.99176 11.8203 2.02473L3.44572 4.37824C3.18118 4.45258 3 4.6807 3 4.93945V13.569C3 14.6914 3.48509 15.8404 4.4417 16.984C5.17231 17.8575 6.18314 18.7345 7.446 19.5909C9.56752 21.0295 11.6566 21.912 11.7445 21.9488C11.8258 21.9829 11.9129 22 12.0001 22C12.0872 22 12.1744 21.983 12.2557 21.9488C12.3435 21.912 14.4326 21.0295 16.5541 19.5909C17.8169 18.7345 18.8277 17.8575 19.5584 16.984C20.515 15.8404 21 14.6914 21 13.569V4.93945C21 4.6807 20.8189 4.45258 20.5543 4.37824Z"
                                    fill="currentColor"></path>
															<path
                                  d="M10.5606 11.3042L9.57283 10.3018C9.28174 10.0065 8.80522 10.0065 8.51412 10.3018C8.22897 10.5912 8.22897 11.0559 8.51412 11.3452L10.4182 13.2773C10.8099 13.6747 11.451 13.6747 11.8427 13.2773L15.4859 9.58051C15.771 9.29117 15.771 8.82648 15.4859 8.53714C15.1948 8.24176 14.7183 8.24176 14.4272 8.53714L11.7002 11.3042C11.3869 11.6221 10.874 11.6221 10.5606 11.3042Z"
                                  fill="currentColor"></path>
														</svg>
													</span>
              <!--end::Svg Icon-->
              <div class="d-flex flex-column">
                <h4 class="mb-1 text-black">Uploading Documents</h4>
                <span class="text-black">Please wait while we upload your documents <strong>{{ totalUploaded }} of {{
                    totalUploads
                  }}</strong>.</span>
              </div>
            </div>
            <div v-if="totalUploaded !== 0 &&  totalUploads === totalUploaded"
                 class="alert alert-success d-flex align-items-center p-5 mb-10">
              <!--begin::Svg Icon | path: icons/duotune/general/gen048.svg-->
              <span class="svg-icon svg-icon-2hx  svg-icon-success me-4">
														<svg width="24" height="24" viewBox="0 0 24 24" fill="none"
                                 xmlns="http://www.w3.org/2000/svg">
															<path opacity="0.3"
                                    d="M20.5543 4.37824L12.1798 2.02473C12.0626 1.99176 11.9376 1.99176 11.8203 2.02473L3.44572 4.37824C3.18118 4.45258 3 4.6807 3 4.93945V13.569C3 14.6914 3.48509 15.8404 4.4417 16.984C5.17231 17.8575 6.18314 18.7345 7.446 19.5909C9.56752 21.0295 11.6566 21.912 11.7445 21.9488C11.8258 21.9829 11.9129 22 12.0001 22C12.0872 22 12.1744 21.983 12.2557 21.9488C12.3435 21.912 14.4326 21.0295 16.5541 19.5909C17.8169 18.7345 18.8277 17.8575 19.5584 16.984C20.515 15.8404 21 14.6914 21 13.569V4.93945C21 4.6807 20.8189 4.45258 20.5543 4.37824Z"
                                    fill="currentColor"></path>
															<path
                                  d="M10.5606 11.3042L9.57283 10.3018C9.28174 10.0065 8.80522 10.0065 8.51412 10.3018C8.22897 10.5912 8.22897 11.0559 8.51412 11.3452L10.4182 13.2773C10.8099 13.6747 11.451 13.6747 11.8427 13.2773L15.4859 9.58051C15.771 9.29117 15.771 8.82648 15.4859 8.53714C15.1948 8.24176 14.7183 8.24176 14.4272 8.53714L11.7002 11.3042C11.3869 11.6221 10.874 11.6221 10.5606 11.3042Z"
                                  fill="currentColor"></path>
														</svg>
													</span>
              <!--end::Svg Icon-->
              <div class="d-flex flex-column">
                <h4 class="mb-1 text-black">Analysing Documents</h4>
                <span class="text-black">Your documents were uploaded successfully. You can leave this page open or visit the <a
                    href="#">Import Scans</a> tab later when documents are analysed.</span>
              </div>
            </div>
            <form class="form" action="#" method="post">
              <!--begin::Input group-->
              <div class="fv-row">
                <!--begin::Dropzone-->
                <div class="dropzone" ref="dropzone">
                  <!--begin::Message-->
                  <div class="dz-message needsclick">
                    <!--begin::Icon-->
                    <i class="bi bi-file-earmark-arrow-up text-primary fs-3x"></i>
                    <!--end::Icon-->

                    <!--begin::Info-->
                    <div class="ms-4">
                      <h3 class="fs-5 fw-bold text-gray-900 mb-1">Drop files here or click to upload.</h3>
                      <span class="fs-7 fw-semibold text-gray-400">Upload up to 10 files</span>
                    </div>
                    <!--end::Info-->
                  </div>
                </div>
                <!--end::Dropzone-->
              </div>
              <!--end::Input group-->
              <!--begin::Input group-->
              <div class="fv-row d-flex mt-5">
                <div class="d-flex flex-row gap-4 flex-wrap " style="flex: 2;">
                  <document-thumbnail v-for="(src, index) in chosenImages"
                                      :class="{'selected': index === selectedIndex}"
                                      :src="src.base64"
                                      :name="src.name"
                                      @click="onPreviewClicked($event, index)"/>
                </div>
                <div v-show="chosenImages.length > 0" class="preview-area text-center flex-column"
                     style="flex: 1; border: 1px solid #CCC;">
                  <div style="height: 300px; width: 100%; ">
                    <div ref="panZoomContainer">
                      <img class="img-fluid " style="max-height: 100%" :src="previewSrc"/>
                    </div>
                  </div>

                  <div style="border-top: 1px solid #CCC;"
                       class="form-check form-check-custom form-check-solid form-check-success p-4">
                    <label class="form-check-label" for="flexCheckDefault">
                      Parse Immediately
                      <a href="#" data-bs-toggle="tooltip" data-bs-placement="bottom"
                         title="Analyses the document when it is uploaded. Uses a scan point.">
                        <!--begin::Svg Icon | path: /var/www/preview.keenthemes.com/kt-products/docs/metronic/html/releases/2022-12-26-231111/core/html/src/media/icons/duotune/general/gen046.svg-->
                        <span class="svg-icon svg-icon-muted svg-icon-1">
                                  <svg width="24" height="24"
                                       viewBox="0 0 24 24" fill="none"
                                       xmlns="http://www.w3.org/2000/svg">
                                <rect opacity="0.3" x="2" y="2" width="20" height="20" rx="10" fill="currentColor"/>
                                <path
                                    d="M11.276 13.654C11.276 13.2713 11.3367 12.9447 11.458 12.674C11.5887 12.394 11.738 12.1653 11.906 11.988C12.0833 11.8107 12.3167 11.61 12.606 11.386C12.942 11.1247 13.1893 10.896 13.348 10.7C13.5067 10.4947 13.586 10.2427 13.586 9.944C13.586 9.636 13.4833 9.356 13.278 9.104C13.082 8.84267 12.69 8.712 12.102 8.712C11.486 8.712 11.066 8.866 10.842 9.174C10.6273 9.482 10.52 9.82267 10.52 10.196L10.534 10.574H8.826C8.78867 10.3967 8.77 10.2333 8.77 10.084C8.77 9.552 8.90067 9.07133 9.162 8.642C9.42333 8.20333 9.81067 7.858 10.324 7.606C10.8467 7.354 11.4813 7.228 12.228 7.228C13.1987 7.228 13.9687 7.44733 14.538 7.886C15.1073 8.31533 15.392 8.92667 15.392 9.72C15.392 10.168 15.322 10.5507 15.182 10.868C15.042 11.1853 14.874 11.442 14.678 11.638C14.482 11.834 14.2253 12.0533 13.908 12.296C13.544 12.576 13.2733 12.8233 13.096 13.038C12.928 13.2527 12.844 13.528 12.844 13.864V14.326H11.276V13.654ZM11.192 15.222H12.928V17H11.192V15.222Z"
                                    fill="currentColor"/>
                                </svg>
                                </span>
                        <!--end::Svg Icon-->


                      </a>
                    </label>
                  </div>
                  <!--begin::Input group-->
                  <select2 ref="categoryChoser"
                           placeholder="Select Merchant"
                           value="Description"
                           code="Id"
                           selected-value="1"
                           :url="'LOOK_UP_CATEGORIES'"
                           :dynamic-creation="true"
                           label="Category"
                           @value-changed="handleCategoryUpdate"/>
                  <textarea class="form-control" :value="scanNotes" @keyup="onScanNotesUpdate($event)"
                            placeholder="Add Notes Here"></textarea>

                </div>
              </div>
              <!--end::Input group-->
            </form>
          </div>
          <!--begin::Step 2-->


        </div>
        <!--end::Group-->

        <!--begin::Actions-->
        <div class="d-flex flex-stack">
          <!--begin::Wrapper-->
          <div class="me-2">
            <button type="button" class="btn btn-light btn-active-light-primary"
                    data-kt-stepper-action="previous">
              Back
            </button>
          </div>
          <!--end::Wrapper-->

          <!--begin::Wrapper-->
          <div>
            <button type="button" class="btn btn-primary" data-kt-stepper-action="submit">
                        <span class="indicator-label" @click.prevent="doUpload">
                            Submit
                        </span>
              <span class="indicator-progress">
                            Please wait... <span class="spinner-border spinner-border-sm align-middle ms-2"></span>
                        </span>
            </button>

            <button type="button" class="btn btn-primary" data-kt-stepper-action="next">
              Continue
            </button>
          </div>
          <!--end::Wrapper-->
        </div>
        <!--end::Actions-->
      </form>
      <!--end::Form-->
    </div>
  </div>
  <!--end::Stepper-->
</template>

<script>

  import { APIS } from '../../core/api-urls'
  import ApiService, { EnvironmentURL } from '../../core/services/api-service'
  import { base64UploadedImage } from '../../core/services/file'
  import Panzoom from '@panzoom/panzoom'
  import DocumentThumbnail from '../documents/document-thumbnail.vue'
  import Select2 from '../inputs/select2.vue'

  export default {
    name: 'upload-scans',
    components: { Select2, DocumentThumbnail },
    data () {
      return {
        analyseType: '',
        dropzone: null,
        stepper: null,

        previewSrc: 'https://place-hold.it/300x300',
        panZoom: null,
        selectedIndex: -1,
        chosenImages: [],
        chosenCategory: '',
        scanNotes: '',
        totalUploads: 0,
        totalUploaded: 0,

        categories: []
      }

    },
    async created () {
      let response = await ApiService.GenerateGetRequest(APIS.LOOK_UP_CATEGORIES)
      if (response.status === 200) {
        this.categories = response.data
      }
    },
    mounted () {
      this.$nextTick(() => {
        var element = this.$refs.kt_stepper_example_vertical
        this.stepper = new KTStepper(element)
        this.stepper.on('kt.stepper.next', (stepper) => {
          switch (this.stepper.currentStepIndex) {
            case 1:
              if (this.analyseType === '') {
                toastr.error('Choose an import type', 'Invalid Import Type')
                return
              }
              break

          }

          if (this.stepper.currentStepIndex !== 2)
            this.stepper.goNext() // go next step
        })
        this.stepper.on('kt.stepper.previous', function (stepper) {
          stepper.goPrevious() // go previous step
        })

        this.dropzone = new Dropzone(this.$refs.dropzone, {
          url: EnvironmentURL + APIS.ANALYSE_DOCUMENTS_URL,
          paramName: 'f', // The name that will be used to transfer the file
          maxFiles: 10,
          maxFilesize: 10, // MB
          addRemoveLinks: true,
          acceptedFiles: 'image/*',
          disablePreviews: true,
          parallelUploads: 10,
          autoProcessQueue: false,
          headers: {
            'authorization': `Bearer ${this.$store.getters['Auth/token']}`
          }
        })
        this.dropzone.on('queuecomplete', (file) => {
          this.dropzone.removeAllFiles(true)
          this.chosenImages = []
          this.selectedIndex = -1
        })
        this.dropzone.on('success', (file) => {
          this.totalUploaded++
        })
        this.dropzone.on('sending', (file, xhr, formData) => {
          formData.append('token', this.$store.getters['Auth/token'])
          let f = this.chosenImages.find(x => x.name === file.name)
          formData.append('c', f.category)
          formData.append('st', this.analyseType)

          formData.append('n', f.notes)
        })
        this.dropzone.on('addedfile', (file) => {
          base64UploadedImage(file, (base64) => {

            this.chosenImages.push({
              name: file.name,
              base64,
              category: 1,
              notes: '',
            })
            if (this.selectedIndex < 0) {
              this.selectedIndex = 0
              this.updatePreview(this.selectedIndex)
            }
          })
        })

        this.panZoom = Panzoom(this.$refs.panZoomContainer, {
          maxScale: 5,
        })

        this.$refs.panZoomContainer.addEventListener('wheel', this.panZoom.zoomWithWheel)

      })
    },
    methods: {
      handleCategoryUpdate (value) {
        this.chosenImages[this.selectedIndex].category = value
      },
      uploadScans () {
        this.dropzone.processQueue()
      },
      doUpload () {
        let queuedFiles = this.dropzone.getQueuedFiles()
        if (queuedFiles.length === 0)
          return
        this.totalUploaded = 0
        this.totalUploads = queuedFiles.length
        this.uploadScans()
      },
      onPreviewClicked ($event, index) {
        this.selectedIndex = index
        this.updatePreview(index)
      },
      onScanNotesUpdate (event) {
        console.log('Updating', this.chosenImages[this.selectedIndex], event.target.value)
        this.chosenImages[this.selectedIndex].notes = event.target.value
      },
      updatePreview (index) {
        let chosenImage = this.chosenImages[index]
        this.previewSrc = chosenImage.base64
        this.scanNotes = chosenImage.notes
        $(this.$refs.categoryChoser).val(chosenImage.category).trigger('change')
        this.panZoom.reset()
      }

    }
  }
</script>

<style scoped>

  .dropzone.dz-started .dz-message {
    display: flex;
  }

  .selected {
    border: 1px solid #50cd89;
  }
</style>
