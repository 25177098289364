<script>
  import PermissionService from "@/core/services/permission-service";

  export default {
    name: "permission-menu-sub-item",
    computed: {
      PermissionService() {
        return PermissionService
      }
    },
    props: ["url", "title", "page", "action"]
  }
</script>

<template>
  <router-link v-if="PermissionService.checkPermission(page, action)" class="menu-link" :to="url">
                	<span class="menu-bullet">
														<span class="bullet bullet-dot"></span>
													</span>
    <span class="menu-title">{{ title }}</span>
  </router-link>
</template>