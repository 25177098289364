<template>
  <div class="d-flex flex-row justify-content-between mb-1">
    <div>
      <h5 class="">Select a Document </h5>
      <div class="fs-5 text-gray-600 ">
        You can edit your data to be more detailed as receipts usually have abbreviated item names from the company. The
        <strong>Merchant Name</strong>, <strong>Transaction Date</strong> and <strong>Total</strong> are mandatory
        fields.
      </div>
    </div>
  </div>
  <div class="d-flex flex-row justify-content-evenly flex-grow-1" data-kt-stepper-element="content">
    <div class="flex-column justify-content-center flex-wrap overflow-auto h-600px pe-10">
      <document-thumbnail v-for="(scan, index) in scans"
                          :class="{'selected': index === selectedIndex}"
                          :src="scan.ScanImages[0].DownloadUrl"
                          :name="scan.ScanImages[0].file_name"
                          @click="onPreviewClicked($event, index)"/>
    </div>
    <div class="flex-fill d-flex flex-grow-1">
      <div style="flex: 2;">
        <import-overview v-if="selectedIndex >= 0" :content="selectedData" @contentUpdate="contentUpdated"/>
        <div class="d-flex justify-content-end" style="padding: 0 2.25rem"
             v-if="scans.length > 0 && selectedIndex >= 0">
          <loading-button @click="importItem" active-text="Importing" :active="importActive" class="me-0"
                          :text="'Import'"/>
        </div>
      </div>
      <div style="border: 1px solid #CCC; flex: 1">
        <div style="height: 300px; width: 100%; " class="d-flex justify-content-center" ref="panZoomContainer">
          <img class="img-fluid" :src="previewSrc"/>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import ApiService, {EnvironmentURL} from "../../core/services/api-service";
import {APIS} from "../../core/api-urls";
import ImportOverview from "./import-overview.vue";
import DocumentThumbnail from "../documents/document-thumbnail.vue";
import LoadingButton from "../buttons/LoadingButton.vue";
import Panzoom from "@panzoom/panzoom";

export default {
  name: "import-scans",
  components: {LoadingButton, DocumentThumbnail, ImportOverview},
  emits: ["scanCount"],
  data() {
    return {
      scans: [],
      selectedIndex: -1,
      selectedData: null,
      showScan: false,
      previewSrc: "https://place-hold.it/300x300",
      panZoom: null,

      importActive: false,
    }
  },
  created() {
    this.fetchImportScans();
    socket.on(this.$store.getters["Auth/token"] + ".parsed", (message) => {
      console.log("Message", message);
      this.fetchImportScans();
    });
  },
  mounted() {
    this.$nextTick(() => {
      this.panZoom = Panzoom(this.$refs.panZoomContainer, {
        maxScale: 10,
      });
      this.$refs.panZoomContainer.addEventListener('wheel', this.panZoom.zoomWithWheel);
    });


  },
  methods: {
    async fetchImportScans() {
      let response = await ApiService.GenerateGetRequest(APIS.READY_IMPORT_DOCUMENTS_URL);
      if (response.status === 200)
        this.scans = response.data;

      console.log("SCANS", response);
      this.$emit("scanCount", this.scans.length);
    },
    onPreviewClicked($event, index) {
      this.selectedIndex = index;
      this.selectedData = JSON.parse(this.scans[index].RawData);
      this.previewSrc = this.scans[index].ScanImages[0].DownloadUrl;
    },

    contentUpdated(jsonString) {
      this.scans[this.selectedIndex].RawData = jsonString;
    },
    async importItem() {
      // todo ensure valid data
      if (!this.importActive) {
        let scan = this.scans[this.selectedIndex];
        let json = JSON.parse(scan.RawData);
        if (!json.MerchantName || json.MerchantName.value === "") {
          toastr.error("Enter Merchant Name", "Invalid Merchant Name");
          return;
        }
        if (!json.TransactionDate || json.TransactionDate.value === "") {
          toastr.error("Enter Transaction Date", "Invalid Transaction Date");
          return;
        }
        if (!json.Total || json.Total.value === "") {
          toastr.error("Enter Total", "Invalid Total");
          return;
        }
        this.importActive = true;
        let response = await ApiService.GeneratePostRequest(APIS.IMPORT_DOCUMENT_URL, {
          rd: scan.RawData,
          si: scan.Id
        });
        if (response.status === 200) {
          this.scans.splice(this.selectedIndex, 1);
          this.selectedIndex = -1;
          this.previewSrc = "https://place-hold.it/300x300";
          toastr.success("Your scan has been successfully imported", "Scan Imported");
        } else {
          toastr.error(response.message, "Import Failed");
        }
        this.$emit("scanCount", this.scans.length);
        this.importActive = false;
      }
    }
  }
}
</script>

<style scoped>
.selected {
  border: 1px solid #50cd89;
}
</style>
