import JWTService from "@/core/services/jwt-service";

export default {
    namespaced: true,
    state: () => ({
        token: null,
        user: {
            name: "",
            email: "",
            phone: ""
        },
        permissions: [],
    }),
    mutations: {
        // MUTATIONS - Any synchronous global state actions must be called with mutations.
        // I.E we might update Signer Information using a mutation but perform an ajax request which updates the token in an action
        signInSuccessful(state, payload) {
            state.token = payload.token;
            const {header, data} = JWTService.decodeJWT(payload.token);
            
            state.user = data.employee;
            state.permissions = data.permissions;
        },
        signOut(state) {
            state.token = null;
            state.user = {name: "", email: "", password: ""};
        },
    },
    actions: {
        
        async authenticate({commit}, payload) {

        },
        signOut({commit}) {

        }
    },
    getters: {
        token: (state) => {
            return state.token;
        },
        permissions: (state) => {
            return state.permissions;
        },
        user: (state) => {
            return state.user;
        },
    }
}
