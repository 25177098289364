<template>
  <button class="btn btn-primary me-2 flex-shrink-0" :data-kt-indicator="active ? 'on' : ''">
    <!--begin::Indicator label-->
    <span class="indicator-label" data-kt-translate="sign-in-submit">{{ text }}</span>
    <!--end::Indicator label-->
    <!--begin::Indicator progress-->
    <span class="indicator-progress">
												<span data-kt-translate="general-progress">{{ activeText ?? 'Please wait...' }}</span>
												<span class="spinner-border spinner-border-sm align-middle ms-2"></span>
											</span>
    <!--end::Indicator progress-->
  </button>
</template>

<script>
export default {
  name: "LoadingButton",
  props: ["text", "activeText", "active"],
  emits: [],
}
</script>

<style scoped>

</style>
