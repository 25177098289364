<script>
  import PermissionService from "@/core/services/permission-service";

  export default {
    name: "permission-hide",
    computed: {
      PermissionService() {
        return PermissionService
      }
    },
    props: [ "page", "action"]
  }
</script>

<template>
  <template v-if="PermissionService.checkPermission(page, action)">
    <slot></slot>
  </template>
</template>