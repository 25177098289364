<script>
  import PermissionService from "@/core/services/permission-service";

  export default {
    name: "permission-menu-root-item",
    computed: {
      PermissionService() {
        return PermissionService
      }
    },
    props: ["url", "title", "page", "icon", "action"]
  }
</script>

<template>
  <router-link v-if="PermissionService.checkPermission(page, action)" class="menu-item" :to="url">
      <!--begin:Menu link-->

  </router-link>
</template>