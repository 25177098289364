<template>
  <h2>Manage Role Permissions</h2>

  <div class="card">
    <!--begin::Card header-->
    <div class="card-header border-0 pt-6">
      <!--begin::Card title-->
      <div class="card-title">
        <!--begin::Search-->
<!--        <div class="d-flex align-items-center position-relative my-1">
          <i class="ki-duotone ki-magnifier fs-3 position-absolute ms-5">
            <span class="path1"></span>
            <span class="path2"></span>
          </i>
          <input type="text" data-kt-user-table-filter="search" class="form-control form-control-solid w-250px ps-13"
                 placeholder="Search Permissions"/>
        </div>-->
        <!--end::Search-->
      </div>
      <!--begin::Card title-->
      <!--begin::Card toolbar-->
      <div class="card-toolbar">
        <!--begin::Toolbar-->
        <!--begin::Group actions-->
      </div>
      <!--end::Card toolbar-->
    </div>
    <!--end::Card header-->
    <!--begin::Card body-->
    <div class="card-body py-4">
      <!--begin::Table-->
      <datatable ref="dataTable" :url="'GET_SYSTEM_ROLES'"
                 :show-context-actions="false"
                 :columns="columns"
                 :table-actions="tableActions"
                 @rowClicked="handleRowInteraction"/>
      <!--end::Table-->
    </div>
    <!--end::Card body-->

    <!--end::Group actions-->
    <!--begin::Modal - Adjust Balance-->
    <!--begin::Modal - Add task-->
    <div class="modal fade" id="kt_modal_add_role_permissions" tabindex="-1" aria-hidden="true">
      <!--begin::Modal dialog-->
      <div class="modal-dialog modal-dialog-centered mw-650px">
        <!--begin::Modal content-->
        <div class="modal-content">
          <!--begin::Modal header-->
          <div class="modal-header" id="kt_modal_add_role_permissions_header">
            <!--begin::Modal title-->
            <h2 class="fw-bold">Edit Role Permissions</h2>
            <!--end::Modal title-->
            <!--begin::Close-->
            <div class="btn btn-icon btn-sm btn-active-icon-primary" data-kt-users-modal-action="close"  data-bs-dismiss="modal">
              <i class="ki-duotone ki-cross fs-1">
                <span class="path1"></span>
                <span class="path2"></span>
              </i>
            </div>
            <!--end::Close-->
          </div>
          <!--end::Modal header-->
          <!--begin::Modal body-->
          <div class="modal-body px-5 my-7">
            <!--begin::Form-->
            <form id="kt_modal_add_role_permissions_form" class="form" action="#">
              <!--begin::Scroll-->
              <div class="d-flex flex-column scroll-y px-5 px-lg-10" id="kt_modal_add_role_permissions_scroll"
                   data-kt-scroll="true" data-kt-scroll-activate="true" data-kt-scroll-max-height="auto"
                   data-kt-scroll-dependencies="#kt_modal_add_role_permissions_header"
                   data-kt-scroll-wrappers="#kt_modal_add_role_permissions_scroll" data-kt-scroll-offset="300px">
                <!--begin::Input group-->
                <div class="fv-row mb-7">
                  <!--begin::Label-->
                  <label class="required fw-semibold fs-6 mb-2">Role Name</label>
                  <!--end::Label-->
                  <!--begin::Input-->
                  <input type="text" v-model="nameVM" readonly class="form-control form-control-solid mb-3 mb-lg-0"
                         placeholder="Role Name"/>
                  <!--end::Input-->
                </div>
                <!--end::Input group-->
                <!--begin::Input group-->
                <div class="fv-row mb-7">
                  <!--begin::Label-->
                  <label class="required fw-semibold fs-6 mb-2">Permissions</label>
                  <div id="kt_docs_jstree_checkable"></div>
                </div>
              </div>
              <!--end::Scroll-->
              <!--begin::Actions-->
              <div class="text-center pt-10">
                <p class="text-danger">{{ saveErrorMsg }}</p>
                <button type="reset" class="btn btn-light me-3" data-bs-dismiss="modal">Discard</button>
                <loading-button @click.prevent="save"
                                text="Save"
                                active-text="Saving"
                                :active="isSaving"/>
              </div>
              <!--end::Actions-->
            </form>
            <!--end::Form-->
          </div>
          <!--end::Modal body-->
        </div>
        <!--end::Modal content-->
      </div>
      <!--end::Modal dialog-->
    </div>
    <!--end::Modal - Add task-->
  </div>

</template>

<script>
  import ApiService from '@/core/services/api-service'
  import { APIS } from '@/core/api-urls'
  import Datatable from '@/components/tables/datatable.vue'
  import LoadingButton from '@/components/buttons/LoadingButton.vue'

  export default {
    name: 'ManageRolePermissions',
    components: { LoadingButton, Datatable, },
    data () {
      return {
        employees: [],
        systemPermissions: [],

        tableActions: [{
          action: 'edit',
          class: '.edit'
        }],
        columns: [
          {
            title: 'Name',
            data: 'Name',
            config: {
              className: 'd-flex align-items-center',
              targets: 0,
              orderable: true,
              render: function (data, type, object) {
                return `<a href="#" class="edit" data-bs-toggle="modal" data-bs-target="#kt_modal_add_role_permissions">${data}</a>`
              }
            }
          }, {
            title: 'Description',
            data: 'Description',
            config: {
              targets: 1,
              orderable: true,
              render: function (data, type, object) {
                return data
              }
            }
          }
        ],

        nameVM: '',
        descriptionVM: '',
        saveErrorMsg: '',
        selectedRole: null,
        isSaving: false,
      }

    },
    async created () {
      this.systemRoles = await ApiService.GenerateGetRequest(APIS.GET_SYSTEM_ROLES)
      this.systemPermissions = await ApiService.GenerateGetRequest(APIS.GET_PERMISSIONS)
    },
    mounted () {

    },
    updated () {

    },
    computed: {
      roleTree () {
        const array = []
        const obj = {}
        this.systemPermissions.forEach(permission => {
          if (!obj[permission.Page])
            obj[permission.Page] = [{ id: permission.Id, text: permission.Action }]
          else
            obj[permission.Page].push({ id: permission.Id, text: permission.Action })
        })

        for (const key in obj) {
          const o = {}
          o['text'] = key
          o['children'] = obj[key].map(item => {
            const isSelected = this.systemRoles.find((role) => role.Id === this.selectedRole.Id).Permissions.find(p => p.PermissionId === item.id) !== undefined
            return {
              text: item.text.toUpperCase(),
              state: {
                perm_id: item.id,
                selected: isSelected,
              }
            }
          })
          array.push(o)
        }
        return array
      }
    },
    methods: {
      async save () {
        this.isSaving = true
        this.saveErrorMsg = ''

        const jsTreeEl = $('#kt_docs_jstree_checkable')
        const items = jsTreeEl.jstree().get_json()

        let arr = []
        // returns, [[1,2], [2,3]] each index is a permission and values is sub permissions
        let idArrays = items.map(item => {
          const ids = []
          item.children.forEach((child) => {
            if (child.state.selected)
              ids.push(child.state.perm_id)
          })

          return ids
        })

        // makes it [1, 2, 3, 4]
        idArrays.forEach(idArr => {
          arr = arr.concat(idArr)
        })

        const payload = {
          RoleId: this.selectedRole.Id,
          PermissionIds: arr,
        }

        try {
          console.log(payload.permissions)
          let response = await ApiService.GeneratePostRequest(APIS.ADD_ROLE_PERMISSIONS_BULK, payload)

          Swal.fire({
            text: 'Role Permission Created!',
            icon: 'success',
            buttonsStyling: false,
            confirmButtonText: 'Close!',
            customClass: {
              confirmButton: 'btn btn-primary'
            }
          })

          this.$refs.dataTable.reload()
        } catch (error) {
          this.saveErrorMsg = error.message
        }
        this.isSaving = false
      },
      async handleRowInteraction (e, action, data) {
        this.selectedRole = data
        if (action === 'edit') {
          this.nameVM = data.Name
          console.log(data)
          const jsTreeEl = $('#kt_docs_jstree_checkable')
          if (jsTreeEl.length > 0 && jsTreeEl.jstree(true)) {
            jsTreeEl.jstree().destroy()
          }
          $('#kt_docs_jstree_checkable').jstree({
            'plugins': ['wholerow', 'checkbox', 'types'],
            'core': {
              'themes': {
                'responsive': false
              },
              'data': this.roleTree
            },
            'types': {
              'default': {
                'icon': 'ki-solid ki-folder text-warning'
              },
              'file': {
                'icon': 'ki-solid ki-file  text-warning'
              }
            },
          })
        } else if (action === 'delete') {
          try {
            let response = await ApiService.GenerateDeleteRequest(APIS.DELETE_ROLES + data.id)
            this.$refs.dataTable.reload()
          } catch (error) {
            Swal.fire({
              text: 'Unable to delete role permission! ' + error.message,
              icon: 'error',
              buttonsStyling: false,
              confirmButtonText: 'Close!',
              customClass: {
                confirmButton: 'btn btn-primary'
              }
            })
          }
        }

      }
    }
  }
</script>

