import axios from "axios";
import store from "../../store/index";

const URL = "http://codelabstt-demo.com/api";

export const EnvironmentURL = URL;
axios.interceptors.response.use(
	(response) => response, // Forward successful responses
	(error) => {
		const originalRequest = error.config;
		if (error.response.status === 403 && !originalRequest._retry) {
			originalRequest._retry = true;
			return axios.post(URL +'/auth/refresh-token')
			            .then((response) => {
				            const newAccessToken = response.data.accessToken;
				            store.commit("Auth/signInSuccessful", {token: newAccessToken});
				            originalRequest.headers.Authorization = `Bearer ${newAccessToken}`;
				            return axios(originalRequest);
			            })
			            .catch((err) => {
				            console.error("Token refresh failed. Please log in again.");
				            return Promise.reject(err);
			            });
		}
		return Promise.reject(error);
	}
);


const GlobalErrorHandler = function (err) {
	// HANDLE API ERRORS HERE like 403 / 401 - unauthorised. To log out client
	if (err && err.response.status === 403 || err.response.status === 401) {
		store.dispatch("Auth/signOut");
	} else {
		throw new Error(err.response.data.message);
	}
}

class ApiService {
	
	
	static async verifyToken(token) {
		return await this.GeneratePostRequest("verify", {token});
	}
	
	
	static async GenerateGetRequest(endpoint, data) {
		let req = null;
		try {
			req = await axios({
				                  method: 'get',
				                  url: URL + endpoint,
				                  headers: {
					                  'authorization': `Bearer ${store.getters["Auth/token"]}`,
				                  },
				                  params: {
					                  ...data,
				                  }
			                  });
		} catch (err) {
			GlobalErrorHandler(err);
		}
		if (req && req["data"])
			req = req["data"];
		
		return req;
	}
	
	static async GeneratePostRequest(endpoint, data) {
		let req = null;
		try {
			req = await axios({
				                  method: 'post',
				                  url: URL + endpoint,
				                  headers: {
					                  'authorization': `Bearer ${store.getters["Auth/token"]}`,
				                  },
				                  data: {
					                  ...data,
				                  }
			                  })
		} catch (err) {
			GlobalErrorHandler(err);
		}
		
		return req;
	}
	
	static async GeneratePostFormDataRequest(endpoint, data) {
		let req = null;
		try {
			req = await axios({
				                  method: 'post',
				                  url: URL + endpoint,
				                  headers: {
					                  'Content-Type': 'multipart/form-data',
					                  'authorization': `Bearer ${store.getters["Auth/token"]}`,
				                  },
				                  data: data
			                  })
		} catch (err) {
			GlobalErrorHandler(err);
		}
		
		return req;
	}
	
	static async GenerateDeleteRequest(endpoint, data) {
		let req = null;
		try {
			req = await axios({
				                  method: 'delete',
				                  url: URL + endpoint,
				                  headers: {
					                  'authorization': `Bearer ${store.getters["Auth/token"]}`,
				                  },
				                  data: {
					                  token: store.getters["Auth/token"],
					                  ...data,
				                  }
			                  })
		} catch (err) {
			GlobalErrorHandler(err);
		}
		
		return req;
	}
	static async GeneratePutRequest(endpoint, data) {
		let req = null;
		try {
			req = await axios({
				                  method: 'put',
				                  url: URL + endpoint,
				                  headers: {
					                  'authorization': `Bearer ${store.getters["Auth/token"]}`,
				                  },
				                  data: {
					                  token: store.getters["Auth/token"],
					                  ...data,
				                  }
			                  })
		} catch (err) {
			GlobalErrorHandler(err);
		}
		
		return req;
	}
}

export default ApiService;
