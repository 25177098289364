<template>
  <div class="document-thumbnail-container mb-2 mt-1">
    <div class="image-container">
      <img class="img-fluid"
           :src="src"
           alt="">
    </div>
    <div class="text-center document-name overflow-hidden"><span>{{ name }}</span></div>
  </div>
</template>

<script>
export default {
  name: "document-thumbnail",
  props: ["src", "name"]
}
</script>

<style scoped lang="scss">
$border-color: rgb(200, 198, 196);

.document-thumbnail-container {
  width: 124px;
  height: 124px;
  border-bottom: 1px solid $border-color;
  background: rgb(250, 249, 248);
  cursor: pointer;
}

.document-thumbnail-container:hover {
  .document-name {
    border-top: 1px solid $border-color;
    border-right: 1px solid $border-color;
    border-left: 1px solid $border-color;
  }
}

.document-thumbnail-container {
  .image-container {
    height: 100px;
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: contain
  }

  .document-name span {
    font-size: 12px;
    font-weight: 400;
    color: rgb(50, 49, 48);
    display: inline;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    height: 20px;
    line-height: 20px;
    width: 110px;
    padding: 0px;
    text-align: center;
  }

}
</style>
